
.courses-page{
    background-color: #F4F5F8;
    padding: 50px 20px;
    min-height: calc(100vh - 356px);

    @media (max-width: 767px){
        padding: 30px 15px;
    }
}
.courses-container{
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;

    @media (max-width: 1199px) {
        max-width: 760px;
    }
    @media (max-width: 830px){
        padding: 0 10px;
        max-width: 728px;
    }
    @media (max-width: 767px){
        padding: 0;
    }

    h1:not(.empty-title){
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 40px;

        @media (max-width: 767px){
            margin-bottom: 25px;
        }
    }
}
.courses-block{
    margin-left: -20px;
    margin-right: -20px;

    @media (max-width: 830px){
        margin-left: -10px;
        margin-right: -10px;
    }
    @media (max-width: 767px){
        justify-content: center;
    }
}
.course-item-block{
    width: 380px;
    padding: 0 20px;

    @media (max-width: 830px){
        padding: 0 10px;
        width: 364px;
    }
}
.course-item {
    width: 100%;
    color: #1D1D1D;
    box-shadow: 0 2px 6px #00000029;
    display: block;
    border-radius: 16px;
    margin: 14px 0 23px;
    position: relative;
    transition: all .3s;
    overflow: hidden;

    .course-item-label{
        position: absolute;
        top: 32px;
        right: -30px;
        background-color: #ffffff;
        transform: rotate(45deg);
        width: 150px;
        text-align: center;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    }

    &:hover{
        color: #1D1D1D;
        text-decoration: none;
        box-shadow: 0 1px 1px #00000029;
    }
    hr{
        margin: 0;
        border-color: #E4E8F2;
    }
}
.course-item-img{
    border-radius: 16px 16px 0 0;
    height: 166px;
    width: 100%;
    object-fit: cover;
}
.course-author{
    background-color: #ffffff;
    padding: 8px 20px;
    display: flex;
    align-items: center;

    img{
        width: 38px;
        height: 38px;
        object-fit: cover;
        margin-right: 6px;
        border-radius: 50%;
    }
    span{
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
    }
}
.course-info-block{
    background-color: #ffffff;
    padding: 10px 20px;
    height: 173px;

    h2{
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
        margin-bottom: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    p{
        font-size: 14px;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0;
    }
}
.course-price{
    background-color: #ffffff;
    padding: 11px 20px;
    border-radius: 0 0 16px 16px;
    line-height: 1.2;
    height: 48px;

    p{
        font-size: 14px;
        color: #444960;
        padding-right: 10px;

        @media (max-width: 358px){
            font-size: 12px;
        }
    }
    span{
        font-size: 20px;
        font-weight: 700;

        @media (max-width: 358px){
            font-size: 16px;
        }
        del{
            font-size: 16px;
            color: #444D68;
            margin-right: 12px;
            margin-bottom: 3px;
            display: inline-block;

            @media (max-width: 358px){
                margin-right: 7px;
                margin-bottom: 0;
            }
        }
    }
}


.course-single-head{
    background-color: #F4F5F8;
    min-height: 186px;

    @media (max-width: 991px){
        align-items: stretch!important;
    }
}
.course-single-head-left{
    width: 51%;
    padding: 20px 20px 20px 11vw;

    @media (max-width: 1199px) {
        padding: 20px 20px 20px 50px;
    }
    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        padding: 35px 15px;
    }
    h1{
        font-size: 30px;
        font-weight: 700;
        line-height: 1.35;
        margin-bottom: 5px;

        @media (max-width: 1199px){
            font-size: 22px;
        }
    }
    p{
        color: #444960;
    }
    .star-block{
        background-color: transparent;
        padding-left: 0;
        margin-bottom: 7px;
    }
}
.course-share-social-block{
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 30px;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 767px){
        bottom: inherit;
        top: 40px;
    }
    a{
        height: 34px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 0 6px 6px 0;
        margin-bottom: 6px;
        white-space: nowrap;
        color: #ffffff;

        &:hover{
            text-decoration: none;

            span{
                text-indent: 0;
                margin-right: 10px;
            }
        }
        span{
            text-indent: -200px;
            text-transform: uppercase;
            font-size: 14px;
            transition: all .3s;

            @media (max-width: 767px){
                display: none;
            }
        }
        img{
            height: 18px;
            width: 20px;
            flex: 0 0 20px;
        }
    }
}
.course-single-price{
    font-size: 30px;
    font-weight: 700;

    @media (max-width: 1199px){
        font-size: 24px;
    }
    del{
        color: #444D68;
        font-size: 17px;
        margin-left: 10px;
    }
}

.course-single-head-right{
    width: 48.9%;

    @media (max-width: 767px){
        width: 100%;
        order: -1;
    }

    img{
        border-radius: 0 0 0 20px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 35.725vw;

        @media (max-width: 767px){
            border-radius: 0;
            height: 60vw;
            max-height: inherit;
        }
    }
}

.course-single-container{
    max-width: 1056px;
    margin: 0 auto;
    padding: 60px 15px;
}
.course-single-content{
    max-width: 800px;
}
.font-20{
    font-size: 20px;
}
.course-single-video-container{
    margin-top: 80px;
}
.btn-free{
    padding: 6px 15px;
    border: solid 1px;
    font-size: 14px;
    background-color: var(--btn1-outline-background-color);
    color: var(--btn1-outline-text-color);
    border-color: var(--btn1-outline-border-color);

    &:hover{
        background-color: var(--btn1-outline-background-hover-color);
        border-color: var(--btn1-outline-background-hover-color);
        color: var(--btn1-outline-text-color-hover);
    }
}
.classes-accordion {
    margin-bottom: 80px;

    > .card .card-header {
        margin-bottom: 3px;
    }

    .card-body {
        padding: 10px 16px 10px 43px;
        border: 1px solid #F1F1F3;
        margin-bottom: 3px;
        font-size: 18px;
        color: #000000;
        border-radius: 3px;
        position: relative;
        display: flex;
        align-items: center;

        @media (max-width: 991px){
            font-size: 16px;
        }
        @media (max-width: 575px) {
            font-size: 14px;
            padding: 8px 10px 8px 12px;
        }
        > img{
            margin-right: 15px;
            width: 21px;
            height: 23px;
            flex: 0 0 21px;

            @media (max-width: 575px){
                margin-right: 10px;
                width: 17px;
                height: 18px;
                flex: 0 0 17px;
            }
        }
        .btn{
            width: 117px;
            flex: 0 0 117px;
            font-size: 14px;
            padding: 7px 15px;

            @media (max-width: 575px){
                padding: 2px 7px;
                width: 100px;
                flex: 0 0 100px;
                white-space: nowrap;
            }
        }
        .episode-time{
            font-size: 15px;
            margin-right: 13px;
            color: #000000;
            text-align: left;
            width: 124px;
            flex: 0 0 124px;

            @media (max-width: 767px){
                display: none;
            }
        }
        .episode-title{
            white-space: normal;
            flex-grow: 1;
            font-size: 16px;
            padding-right: 15px;
            width: auto;

            @media (max-width: 575px){
                flex: 1 1 auto;
                padding-right: 12px;
                font-size: 14px;
            }
        }
    }
    .card-header {
        background-color: transparent;

        .btn {
            text-transform: inherit;
            border: 1px solid #F1F1F3;
            border-radius: 3px;
            padding: 0.8rem 1.2rem 0.8rem 43px;
            background-color: #F4F5F8;
            text-align: left;
            font-size: 18px;
            letter-spacing: 0;
            color: #292929;
            align-items: center;

            &:focus {
                box-shadow: none;
            }
            @media (max-width: 991px){
                font-size: 16px;
            }
            @media (max-width: 767px) {
                font-size: 14px;
                padding: 20px 10px 20px 30px;
            }

            &:after {
                content: "+";
                width: 21px;
                flex: 0 0 21px;
                height: 100%;
                display: inline-flex;
                position: absolute;
                left: 12px;
                top: 0;
                font-size: 36px;
                line-height: 1;
                text-align: center;
                align-items: center;
                font-family: sans-serif;
                justify-content: center;

                @media (max-width: 767px){
                    font-size: 26px;
                    left: 4px;
                }
            }

            &[aria-expanded="true"]:after {
                content: "-";
                padding-bottom: 3px;
            }

        }
    }
}

.episode-title{
    flex-grow: 1;
    margin-right: auto;
    line-height: 1.4;
    padding-right: 15px;

    @media (max-width: 575px){
        width: calc(100% - 81px);
        flex: 0 0 calc(100% - 70px);
        line-height: 1.2;
        padding-right: 10px;
    }
}
.episode-count{
    width: 124px;
    flex: 0 0 124px;
    margin-right: 10px;
    font-size: 16px;

    @media (max-width: 575px){
        margin-right: 0;
        font-size: 12px;
        margin-left: auto;
        position: absolute;
        right: 10px;
        top: 2px;
        width: auto;
    }
}
.episode-time{
    width: 117px;
    flex: 0 0 117px;
    text-align: right;
    font-size: 16px;

    @media (max-width: 575px){
        font-size: 12px;
        margin-left: auto;
        position: absolute;
        right: 10px;
        top: 21px;
        width: 70px;
    }
}


.test-certificate{

    > img{
        width: 45%;
        max-width: 390px;

        @media (max-width: 767px){
            width: 100%;
            max-width: inherit;
        }
    }
}
.test-certificate-info{
    width: 50%;
    max-width: 485px;
    padding-top: 3px;

    @media (max-width: 767px){
        width: 100%;
        order: -1;
        max-width: inherit;
        text-align: center;
        margin-bottom: 30px;
    }
    h2{
        font-size: 25px;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 26px;

        @media (max-width: 899px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    p{
        margin-bottom: 6px;
    }
    h3{
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 46px;

        @media (max-width: 899px){
            font-size: 18px;
            margin-bottom: 35px;
        }
    }
}
.course-coach-section{
    background-color: #F4F5F8;
    padding: 65px 15px;

    @media (max-width: 767px){
        padding: 40px 15px;
    }
    .title-h3{
        margin-top: 0;
        margin-bottom: 43px;
    }
}
.course-coach-container{
    max-width: 1030px;
    margin: 0 auto;

    figure{
        width: 250px;

        @media (max-width: 991px){
            width: 220px;
        }
        @media (max-width: 575px) {
            width: 100%;
            text-align: center;
        }

        img{
            width: 130px;
            height: 130px;
            border-radius: 50%;
            object-fit: cover;
            margin-bottom: 20px;
        }
        h4{
            font-size: 18px;
            font-weight: 700;
        }
        h5{
            font-size: 18px;
        }
    }
}
.course-coach-info{
    width: calc(100% - 250px);
    padding-left: 25px;
    font-size: 18px;
    letter-spacing: 0.54px;
    color: #292929;
    line-height: 1.4;

    @media (max-width: 991px){
        font-size: 16px;
        width: calc(100% - 220px);
        padding-left: 20px;
    }
    @media (max-width: 575px) {
        width: 100%;
        padding-left: 0;
        order: -1;
    }
    h3{
        font-weight: bold;
        font-size: 19px;
        margin-bottom: 40px;
    }
    p{
        margin-bottom: 25px;
    }
}


//video
:root {
    --color-text: #000000;
    --color-bg: #fff;
    --color-bg-btn: #f8f8f8;
    --color-bg-hover: #f2f2f2;
    --color-bg-content: #ffffff;
    --color-bg-page: #ffffff;
}

.dark-mode:root {
    --color-text: #e5e5e5;
    --color-bg: #1F2933;
    --color-bg-btn: #34404a;
    --color-bg-hover: #3E4C59;
    --color-bg-content: #1F2933;
    --color-bg-page: #1F2933;
}
body{
    background: var(--color-bg);
    color: var(--color-text);
}
.episode-section{
    background: var(--color-bg-page);
    height: 100vh;
    min-height: 500px;
    width: calc(100% - 380px);

    @media (max-width: 991px){
        width: 100%;
        height: auto;
        min-height: inherit;
    }
}
.toggle-block {
    position: absolute;
    top: 9px;
    right: 12px;
    padding: 10px;
    width: 320px;
    z-index: 9999;

    label{
        margin: 0 8px;
    }
}
#toggle-mode .toggle-border {
    border: 1px solid #ffffff;
    width: 50px;
    height: 25px;
    display: block;
    border-radius: 20px;
    position: relative;
    background-color: #d1d1cf;
}
#toggle-mode .toggle-indicator {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    position: absolute;
    background-color: #ffffff;
    top: 2px;
    left: 2px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
}
#toggle-mode .toggle-indicator::after {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    bottom: -3px;
    right: 5px;
    background-color: #ffffff;
}
.toogle-span{
    font-size: 13px;
}
.toogle-span-dark{
    color: #B7B7B7;
    font-size: 13px;
}

.dark-mode{

    .video-description-icon{
        color: #ffffff;
    }
    .video-description-icon img{
        filter: brightness(1);
    }
    .video-description .tooltip-inner{
        background-color: #171717;
        color: #ffffff;
    }
    .video-description .arrow::before{
        border-top-color: #171717;
        border-bottom-color: #171717;
    }
    .btn-back{
        color: #fff;

        svg path{
            fill: #fff;
        }
        &:hover{
            color: #ffffff;
        }
    }
    .toogle-span-dark{
        color: #ffffff;
    }
    .toogle-span{
        color: #929292;
    }
    #toggle-mode .toggle-border{
        border-color: white;
        background-color: #ffffff;
    }
    #toggle-mode .toggle-indicator{
        background-color: #1f2933;
        left: calc( 50px - 23px );
        transform: rotate(
                200deg);
        top: 3px;
        overflow: visible;
    }
    .episode-text, .episode-file-item{
        color: #ffffff;
    }
    .video-teacher-info::-webkit-scrollbar-thumb,
    .episode-text::-webkit-scrollbar-thumb{
        background-color: #8d8d8d;
    }
    .video-section .video-js{
        background: var(--color-bg-page);
    }
    .menu-arrow{
        border-left: 1px solid #ffffff;
        border-top: 1px solid #ffffff;
    }
    @media (max-width: 991px){
        .video-info-block{
            border: solid 1px grey;
        }
    }
}

.episode-text{
    background: var(--color-bg-content);
    padding: 4vw;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    @media (max-width: 1199px) {
        padding: 4vw 2vw;
    }
    @media (max-width: 991px){
        padding: 15px;
        margin-bottom: 10px;
        border: solid 1px #d6d6d6;
        border-radius: 5px;
        width: 100%;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #282828;
        border-radius: 16px;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
}
.btn-back {
    position: absolute;
    left: 17px;
    top: 10px;
    padding: 5px 5px;
    border-radius: 3px;
    color: #000;
    z-index: 1111;
    transform: rotate(0deg);
    width: auto;
    right: inherit;

    &:hover {
        text-decoration: none;
        color: black;
        text-shadow: 0 0;
    }
    @media (max-width: 991px){
        left: 15px;
    }
    @media (max-width: 520px) {
        top: 15px;

        span {
            display: none;
        }
    }
}
.position-btn {
    cursor: pointer;
    margin: 0 5px;
}
.video-section {
    width: 100%;
    margin: auto;
    justify-content: space-between;
    height: 100%;
    padding: 63px 15px 20px 15px;
    align-items: flex-start;
    overflow: hidden;

    @media (max-width: 991px) {
        flex-direction: column;
        height: auto;
        max-height: inherit;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 60px 15px 0;
    }
    #youtube_video{
        width: 100%;
        height: 100%;
        background: var(--color-bg-page);

        @media (max-width: 991px) {
            width: 100%;
            height: 55vh;
            margin-bottom: 10px;
            padding: 0;
        }
        &:focus{
            outline: none;
        }
    }
    iframe{
        max-width: 100%;
    }
}
.episode-section .video-section{

    .video-js {
        width: calc(100% - 415px);
        height: 100%;
        margin: auto;
        font-size: 12px;
        padding: 50px 13px;

        @media (max-width: 991px) {
            width: 100%;
            height: auto;
            padding: 0;
            margin-bottom: 10px;
        }

        .vjs-control-bar{
            background-color: #000000;
        }
        .vjs-progress-control .vjs-mouse-display{
            white-space: nowrap;
        }

        .vjs-big-play-button{
            left: calc(50% - 54px);
            top: calc(50% - 27px);
        }
    }
}
.video-info-block {
    width: 380px;
    margin-left: auto;
    flex: 0 0 380px;
    max-height: 100%;
    background: var(--color-bg);
    height: calc(100vh - 63px);
    border-left: solid 1px #d6d6d6;
    margin-top: 63px;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: right .3s;
    right: 0;
    overflow-y: scroll;
    padding-bottom: 20px;

    @media (hover) {
        &::-webkit-scrollbar-thumb {
            background-color: #c8c8c8;
            border-radius: 16px;
        }
        &::-webkit-scrollbar {
            width: 5px;
        }
    }

    @media (max-width: 991px) {
        width: 100%;
        flex: 0 0 auto;
        padding: 0;
        margin-left: 0;
        border: none;
        margin-top: auto;
        flex-grow: 1;
        max-height: inherit;
        height: auto;
        margin-bottom: 12px;
        border-radius: 5px;
        box-shadow: 0 3px 12px #00000024;
    }
    &.closed{
        right: -380px;
    }
}
.custom-video-description{
    max-height: 50px;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 991px){
        max-height: inherit;
    }
    @media (hover) {
        &::-webkit-scrollbar-thumb {
            background-color: #c8c8c8;
            border-radius: 1px;
        }
        &::-webkit-scrollbar {
            width: 10px;
        }
    }
}
.video-info-block-title{
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--color-bg);
    max-height: 125px;
    padding: 0 0.5rem 10px;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 991px) {
        font-size: 16px;
        padding: 9px 12px;
        max-height: inherit;
        overflow: visible;
    }
    @media (hover){
        &::-webkit-scrollbar{
            width: 5px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #c8c8c8;
            border-radius: 16px;
        }
    }
}
.video-teacher-info {
    position: relative;
    transition: all 0.5s;
    width: 100%;
    padding: 0 0 10px 10px;

    @media (max-width: 991px){
        padding: 0 10px 10px 10px;
    }
}

.episode-file-section{

    @media (max-width: 991px){
        height: 55vh;

        > h1{
            font-size: 24px;
        }
    }
}
.episode-file{
    width: 25%;
    min-width: 165px;
    max-width: 250px;
}
.episode-file-item{
    padding: 25px;
    margin-bottom: 20px;

    @media (max-width: 991px){
        width: 146px;
        padding: 0 10px;
    }
    > img{
        width: 52px;
        height: 60px;
        object-fit: contain;
        margin-bottom: 3px;
    }
    p{
        font-size: 14px;
        margin-bottom: .6rem;

        @media (max-width: 500px){
            font-size: 13px;
        }
    }
    .link-blue{
        color: #4B9DFE;
        font-size: 14px;

        &:hover{
            text-decoration: none;
            color: #4b9cfe;
        }
        @media (max-width: 500px){
            font-size: 13px;
        }
        span{
            text-decoration: underline;
        }
    }
}
.menu-arrow{
    border-left: 1px solid #7d7d7d;
    border-top: 1px solid #7d7d7d;
    display: block;
    height: 10px;
    margin-left: auto;
    transform: rotate(
            223deg);
    transition: all 0.3s;
    width: 10px;
    position: absolute;
    right: 12px;
    top: 15px;
}
.accordion-button {
    background-color: var(--color-bg-btn);
    margin-bottom: 2px;

    .btn {
        width: 100%;
        text-align: left;
        color: var(--color-text);
        padding: 0.5rem 10px;
        white-space: normal;
        text-transform: inherit;
        position: relative;
        border-radius: 0;
        border: none;
        min-height: 45px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
        .menu-arrow{
            top: 50%;
            margin-top: -8px;
        }
        &[aria-expanded="true"]{

            .menu-arrow{
                transform: rotate(
                        45deg);
                transform-origin: 0 6px;
            }
        }
        .section-title {
            font-size: 15px;
            font-weight: 500;
            line-height: 1.3;
            display: inline-block;
            padding-right: 30px;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
}
.play-ul {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 10px;

    li {
        position: relative;
        border-top: 1px solid var(--color-bg);
        border-radius: 3px;
        background-color: var(--color-bg);

        &:hover {
            background-color: var(--color-bg-hover);
        }
        a {
            display: -webkit-box;
            display: flex;
            justify-content: space-between;
            color: var(--color-text);
            padding: 13px 10px;
            align-items: center;

            @media (max-width: 767px) {
                padding: 10px;
            }
            &:hover {
                text-decoration: none;
            }
            > img{
                height: 20px;
                width: 20px;
                margin-right: 10px;
                filter: contrast(0.1);
            }
            .play {
                height: 20px;
                width: 20px;
                font-size: 7px;
                background: var(--color-bg);
                border: 1px solid #b2b2b2;
                border-radius: 50%;
                text-align: center;
                padding: 3px 0 0 2px;
                -webkit-box-ordinal-group: 2;
                order: 1;
                margin-right: 10px;
                color: #686F7A;
                line-height: 14px;

                span {
                    vertical-align: 0;
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 4px 0 4px 6px;
                    border-color: transparent transparent transparent #b2b2b2;
                }
            }
            .play-title {
                -webkit-box-flex: 1;
                flex: 1;
                min-width: 1px;
                -webkit-box-ordinal-group: 3;
                order: 2;
                margin-right: 8px;
                line-height: 1.2;
                text-align: left;
                font-size: 13px;

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
            .play-time {
                -webkit-box-ordinal-group: 4;
                order: 3;
                opacity: 0.7;
                margin-right: 20px;
                line-height: 1.2;
                font-size: 9px;

                @media (max-width: 767px) {
                    font-size: 12px;
                }
            }
            .play-btn {
                height: 20px;
                width: 20px;
                font-size: 9px;
                border: 2px solid #cccccc;
                border-radius: 50%;
                text-align: center;
                padding: 3px 0 0 1px;
                order: 4;
                color: #FFF;
                background: #ffffff;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                &.play-btn-active {
                    background-color: #007791;
                    border-color: #007791;
                    span {
                        display: inline-block;

                        &:after {
                            content: '';
                            display: block;
                            width: 5px;
                            height: 9px;
                            border: solid #fff;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}
.play-ul .free-episode{
    margin-right: auto;
    position: absolute;
    right: 10px;
    font-size: 10px;
    top: 0;
    color: #16a8e3;
}
.play-ul .active {
    background-color: var(--color-bg-hover);

    &:hover {
        background-color: var(--color-bg-hover);
    }
}
.empty-title{
    padding: 10vh 10px;
    font-size: 26px
}

.text-black-btn{
    color: #323232;

    &:hover{
        text-decoration: underline;
        color: black;
    }
}
.student-feedback-container{
    padding: 55px 20px 80px;
    max-width: 895px;
    margin: 0 auto;

    h2{
        font-size: 20px;
        margin-bottom: 0;
    }
    .star-block{
        padding-left: 0;
    }
}
.student-feedback-block{
    display: flex;
    align-items: flex-start;
    border-bottom: solid 1px #DDDFE5;
    padding-bottom: 8px;
    margin-bottom: 27px;
    padding-top: 9px;

    > img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
    .star-block{
        margin-bottom: 15px;

        .Stars{
            margin-left: 0;
        }
    }
    figcaption{
        flex-grow: 1;
        padding-left: 12px;

        h3{
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 0;
        }
        p{
            font-size: 15px;
        }
        .star-block span{
            color: #A7A7A7;
        }
    }
}


//star front
@charset 'UTF-8';
.star-count{
    color: #EFCE4A;
    padding-top: 2px;
}
:root {
    --star-size: 23px;
    --star-color: #ddd;
    --star-background: #EFCE4A;
}
.star-block{
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 6px 20px 0;

    span{
        font-size: 13px;

        b{
            font-weight: 600;
        }
    }
    .Stars{
        margin: 0 4px;
    }
}
.Stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: var(--star-size);
    font-family: Times;
    line-height: 1;

    &::before {
        content: '★★★★★';
        letter-spacing: 1px;
        background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
