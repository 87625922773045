* {
    @media print {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }
}

.quiz-index {
    max-width: 1204px;
    padding: 50px 25px;

    @media (max-width: 767px){
        padding: 50px 20px;
    }
    h1:not(.empty-title) {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 37px;
    }
}

.quiz-index-container{
    margin-left: -1.75%;
    margin-right: -1.75%;
}
.quiz-index-item {
    width: 33.33%;
    padding: 15px 1.75%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all .3s;

    @media (max-width: 991px) {
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
    }

    > img{
        border-radius: 16px 16px 0 0;
        height: 16.45vw;
        max-height: 199px;
        width: 100%;
        object-fit: cover;

        @media (max-width: 991px){
            height: 26vw;
            max-height: none;
        }
        @media (max-width: 767px){
            height: 50vw;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 25px;
        color: #6D6D6D;
    }

    p {
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 47px;
    }

    &:hover{

        .share-social-block{
            right: 0;
            opacity: 1;
            transition: right .2s, opacity .3s;
        }
    }
}
.share-social-block{
    position: absolute;
    right: -60px;
    top: 37px;
    display: flex;
    flex-direction: column;
    width: 33px;
    opacity: 0;
    transition: right .5s, opacity .2s;

    @media (hover: none) {
        opacity: 1;
        right: 0;
    }
    a{
        width: 33px;
        height: 29px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 6px 0 0 6px;
        margin-bottom: 6px;

        &:hover{
            text-decoration: none;
        }
        img{
            height: 18px;
            width: 20px;
        }
    }
}
.facebook{
    background-color: #347CD2;
}
.twitter{
    background-color: #1DC4FF;
}
.linkedin{
    background-color: #0371AE;
}
.quiz-index-item-text{
    background-color: #fff;
    border: 1px solid #d6d7d9;
    border-radius: 0 0 16px 16px;
    padding: 15px 15px 35px 15px;
}

.quize-time-section {
    padding: 7px 15px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 13%);
    background-color: #F4F5F8;
    position: sticky;
    top: 0;
    z-index: 2;

    @media (max-width: 1199px){
        top: 50px;
    }
    h1{
        margin: auto;

        @media (max-width: 767px) {
            font-size: 16px;
            order: -1;
            margin: 0 auto 8px;
            text-align: center;
            width: 100%;
        }
    }
    #timer{
        width: 102px;
        flex: 0 0 102px;
    }
}

.quize-time-section-container {
    max-width: 1000px;
    margin: 0 auto;
    font-size: 22px;

    @media (max-width: 767px){
        flex-wrap: wrap;
    }
    i{
        font-style: normal;
    }
}
.timer-k{

    > div{
        display: flex;
    }
}
.clock{
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-animation: clock 10s ease-in-out infinite;
    animation: clock 20s ease-in-out infinite;
}

.clock-cup {
    background: #cfcfcf;
    height: 35%;
    width: 35%;
    border-radius: 50% 50% 0 0/ 100% 100% 0 0;
    overflow: hidden;
}

.clock-top {
    transform: rotate(180deg);
}

.clock-sand {
    background: #4E6177;
    height: 150%;
    width: 150%;
    transform-origin: 0 0;
    animation: sand 20s linear infinite -10s;
}

.delayed {
    animation-delay: 0s;
}

@keyframes clock {
    0% {
        transform: rotate(0deg)
    }
    25%, 50% {
        transform: rotate(180deg)
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sand {
    0%, 100% {
        border-radius: 5%;
        transform: translate(35%, 20%) rotate(45deg);
    }
    15% {
        border-radius: 40%;
        transform: translate(0%, 35%) rotate(-65deg) scale(1.5, 1.5);
    }
    25% {
        border-radius: 20%;
        transform: translate(-15%, 35%) rotate(-90deg) scale(1.2, 1.2);
    }
    50% {
        border-radius: 5%;
        transform: translate(0%, 0%) rotate(-90deg) scale(1, 1);
        opacity: 1;
    }
    50.01%, 64.99% {
        opacity: 0;
    }
    65% {
        border-radius: 35%;
        transform: translate(20%, 50%) rotate(30deg);
        opacity: 1;
    }
    75% {
        border-radius: 10%;
        transform: translate(35%, 40%) rotate(45deg);
    }
}


.quiz-page {
    background-color: #F4F5F8;
    padding-top: 37px;
    padding-bottom: 80px;
    min-height: calc(100vh - 312px);
}

.quiz-container {
    max-width: 1278px;
    margin: 0 auto;
    padding: 0 20px;
}

.quiz-block {
    background-color: #fff;
    margin-bottom: 13px;
    padding: 28px 35px 30px 35px;
    border-radius: 6px;

    @media (max-width: 767px) {
        padding: 28px 20px 30px 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    iframe{
        height: 48.2vw;
        max-height: 450px;
        max-width: 800px;
    }
    video{
        max-width: 800px;
    }
    .checkbox-image{
        margin-left: 0;

        @media (max-width: 767px){
            margin: 0;
            padding: 4px;
            max-width: 228px;
            width: 50%;
            height: 30vw;
            max-height: 170px;
        }
    }
}

.quiz-title {
    color: #444960;
    font-size: 18px;
    margin-bottom: 1.45em;
    font-weight: 500;
}

.quiz-block .custom-checkbox-image {
    input[type=checkbox] + label::before,
    input[type=checkbox] + label::after {
        top: inherit;
        left: inherit;
        bottom: 15px;
        right: 15px
    }

    input[type=checkbox] + label::after {
        top: inherit;
        left: inherit;
        right: 1.1em;
        bottom: 1.3em;
    }

    input[type=checkbox] + label::after {
        top: inherit;
        left: inherit;
        right: .95em;
        bottom: 1.2em;
    }
}

.custom-checkbox-image {
    margin-left: -10px;
    margin-right: -10px;
    display: inline-block;
    position: relative;

    & > div {
        height: 100%;
    }

    input[type=checkbox] + label {
        font-size: 1.2rem;
    }

    input[type=checkbox] + label::before,
    input[type=checkbox] + label::after {
        top: inherit;
        left: inherit;
        bottom: 15px;
        right: 15px
    }

    input[type=checkbox] + label::after {
        top: inherit;
        left: inherit;
        right: .95em;
        bottom: 1.3em;
    }

}

.checkbox-image {
    margin-right: 10px;
    margin-left: 10px;
    width: 217px;
    height: 160px;

    label {
        width: 100%;
        height: 100%;


        img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 0;
            -o-object-fit: cover;
            object-fit: cover;
            padding: 8px;
            border-radius: 10px;
            display: block;
            border: solid 1px #cacaca;
        }
    }
}

.quize-img-modal {

    .modal-header {
        padding: .5rem 1rem;
        border: none;
    }

    .modal-dialog {
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-content {
        height: 100%;
        width: auto;
    }

    .modal-body {

        img {
            width: 100%;
            object-fit: contain;
            max-height: calc(100vh - 5.5rem - 45px);
        }
    }
}

.quize-list {
    padding: 0;

    @media (max-width: 1024px){
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    li {

        button {
            background-color: #ffffff;
            border: solid 1px transparent;
            border-radius: 7px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 3px;
            white-space: nowrap;

            &:hover {
                border-color: #000;
            }

            &.activeBorder {
                border-color: #000;
            }

            &.active {
                color: #ffffff;
                background-color: #444960;
            }
        }
    }
    .slick-track{
        margin: 0 auto;
    }
    .slick-slider{
        padding: 0 20px;
        max-width: 1119px;
        margin: 0 auto;

        @media (max-width: 1300px) {
            max-width: 820px;
        }
        .slick-prev{
            left: -15px;
        }
        .slick-next{
            right: -15px;
        }
    }
    .slick-slide{

        li{
            text-align: center;

            button{
                display: inline-flex;
            }
        }
    }
}




.quiz-finish-page {
    background-color: #F4F5F8;
    padding-top: 37px;
    padding-bottom: 80px;
}

.quiz-finish-container {
    max-width: 940px;
    margin: 0 auto;
    padding: 0 20px;

    h1{
        font-size: 20px;
        color: #1D1D1D;
        font-weight: 700;
        margin-bottom: 40px;
    }
}
.quiz-finish-block {
    background-color: #fff;
    margin-bottom: 13px;
    padding: 30px 0 30px 0;
    border-radius: 6px;

    img {
        max-width: 250px;
        height: auto;
    }
    .correct-answer:after{
        width: 23px;
        height: 23px;
        right: 25px;
        top: calc(50% - 12px);
        background-size: 23px 23px;

        @media (max-width: 767px){
            right: 10px;
        }
    }
}
.answer-title{
    padding: 0 40px;
    color: #444960;
    font-size: 18px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        padding: 0 20px;
    }
}
.answer-text{
    font-size: 18px;
    color: #444960;
    padding: 2px 60px 2px 40px;
    margin-bottom: 10px;

    @media (max-width: 767px) {
        padding: 2px 40px 2px 20px;
    }
    &:last-of-type{
        margin-bottom: 0;
    }
}
.answer-img{
    padding: 10px 60px 10px 40px;

    @media (max-width: 767px) {
        padding: 10px 40px 10px 20px;
    }

    img{
        border-radius: 3px;
    }
}

.correct-answer{
    background-color: #F1FAF1;
    position: relative;

    &:after{
        content: "";
        position: absolute;
        right: 10px;
        top: calc(50% - 7px);
        width: 14px;
        height: 14px;
        display: block;
        background-size: 14px 14px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%2338a78c;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(0 -0.001)'%3E%3Cg transform='translate(0 0.001)'%3E%3Cpath class='a' d='M11.5,0A11.5,11.5,0,1,0,23,11.5,11.513,11.513,0,0,0,11.5,0Zm0,21.115A9.615,9.615,0,1,1,21.115,11.5,9.626,9.626,0,0,1,11.5,21.116Z' transform='translate(0 -0.001)'/%3E%3Cpath class='a' d='M76.749,90.585l-6.828,6.828L66.8,94.292a.943.943,0,0,0-1.333,1.333l3.788,3.788a.943.943,0,0,0,1.333,0l7.495-7.495a.943.943,0,0,0-1.333-1.333Z' transform='translate(-60.274 -83.499)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

        @media (max-width: 767px){
            top: calc(50% - 5px);
            width: 12px;
            height: 12px;
            background-size: 12px 12px;
        }
    }
}

.correct-answer-but-not-set {
    background-color: #FFFAD1;
    position: relative;

    &:after{
        content: "";
        position: absolute;
        right: 25px;
        top: calc(50% - 12px);
        width: 23px;
        height: 23px;
        display: block;
        background-size: 23px 23px;
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'%3E%3Cg id='Group_16493' data-name='Group 16493' transform='translate(-1082 -315)'%3E%3Cg id='Group_16494' data-name='Group 16494'%3E%3Cg id='cancel_23_' data-name='cancel (23)' transform='translate(1082.001 314.931)'%3E%3Cg id='Group_8829' data-name='Group 8829' transform='translate(-0.001 0.07)'%3E%3Cg id='Group_8828' data-name='Group 8828' transform='translate(0 0)'%3E%3Cpath id='Path_2215' data-name='Path 2215' d='M19.637,3.434a11.5,11.5,0,1,0,0,16.271A11.515,11.515,0,0,0,19.637,3.434Zm-1.26,15.011a9.722,9.722,0,1,1,0-13.751A9.731,9.731,0,0,1,18.377,18.445Z' transform='translate(0 -0.07)' fill='%23444960'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Crect id='Rectangle_1057' data-name='Rectangle 1057' width='2' height='16' rx='1' transform='translate(1098.109 320) rotate(42)' fill='%23444960'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        @media (max-width: 767px){
            right: 10px;
        }
    }
}

.wrong-answer{
    background-color: #F8ECEC;
    position: relative;

    &:after{
        content: "";
        position: absolute;
        right: 25px;
        top: calc(50% - 12px);
        width: 23px;
        height: 23px;
        display: block;
        background-size: 23px 23px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23d22a2a;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(0 -0.069)'%3E%3Cg transform='translate(0 0.07)'%3E%3Cg transform='translate(0 0)'%3E%3Cpath class='a' d='M19.637,3.434a11.5,11.5,0,1,0,0,16.271A11.515,11.515,0,0,0,19.637,3.434Zm-1.26,15.011a9.722,9.722,0,1,1,0-13.751A9.731,9.731,0,0,1,18.377,18.445Z' transform='translate(0 -0.07)'/%3E%3C/g%3E%3C/g%3E%3Cg transform='translate(7.309 7.273)'%3E%3Cpath class='a' d='M175.457,171.7l-2.649-2.645,2.649-2.645a.936.936,0,1,0-1.323-1.324l-2.65,2.647-2.65-2.647a.936.936,0,1,0-1.323,1.324l2.649,2.645-2.649,2.645a.936.936,0,0,0,1.323,1.324l2.65-2.647,2.65,2.647a.936.936,0,1,0,1.323-1.324Z' transform='translate(-167.236 -164.813)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

        @media (max-width: 767px){
            right: 10px;
        }
    }
}
.quiz-finish-teacher{
    display: flex;
    align-items: flex-start;
    padding: 0 40px;

    @media (max-width: 767px){
        padding: 0 20px;
    }
    @media (max-width: 575px){
        flex-direction: column;
    }
}
.quiz-finish-teacher-left{
    padding-right: 40px;
    flex-grow: 1;
    max-width: 580px;

    @media (max-width: 575px){
        padding-right: 0;
        margin-bottom: 20px;
    }
    p{
        border: 1px solid #AEB5BD;
        border-radius: 6px;
        padding: 10px 16px;
    }
    textarea.form-control{
        border-color: #4CB8D4;
        color: #4CB8D4;
        border-radius: 6px;
        resize: none;
        height: 76px;
        margin-bottom: 5px;

        &::placeholder{
            color: #4CB8D4;
        }
    }
}

.quiz-finish-teacher-right{
    white-space: nowrap;

    .custom-checkbox-glogal input + label{
        font-size: 1.1rem;
    }
}
.custom-checkbox-glogal.radio-correct input + label::after{
    border-color: #50a88c;
}
.custom-checkbox-glogal.radio-correct input:checked + label::before{
    border-color: #50a88c;
}

.custom-checkbox-glogal.radio-wrong input + label::after{
    border-color: #c71d2b;
}
.custom-checkbox-glogal.radio-wrong input:checked + label::before{
    border-color: #c71d2b;
}

.quiz-finish-student{
    padding: 13px 40px;

    p{
        background-color: #ffffff;
        max-width: 540px;
        margin-bottom: 0;
        padding: 10px 15px 30px;
        border-radius: 6px;
    }
}
.quiz-finish-comment{
    padding: 15px 40px 0;

    h6{
        color: #4CB8D4;
        font-weight: 400;
    }
    p{
        color: rgb(0 0 0 / 50%);
        max-width: 540px;
        margin-bottom: 0;
    }
}
.print-down{

    a{
        font-weight: 600;
        color: #3f93d8;
        margin-left: 20px;
        display: inline-flex;
        align-items: center;
        font-size: 17px;

        img{
            margin-right: 5px;
        }
    }
}

#TenantQuizzesApp {
    figure {
        &.media {
            display: block;
        }
    }
}
