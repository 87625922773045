
.blog-page {
    padding: 50px 22px 0;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    @media (max-width: 1199px){
        max-width: 838px;
        padding: 45px 20px 90px;
    }
    @media (max-width: 767px) {
        max-width: 429px;
    }
    h1:not(.empty-title) {
        font-size: 37px;
        margin-bottom: 16px;

        @media (max-width: 767px) {
            font-size: 30px;
            text-align: center;
            width: 100%;
        }
    }
    .pagination {
        display: flex;
        justify-content: flex-end;
        list-style: none;
        width: 100%;
        padding: 0 22px;

        @media (max-width: 767px){
            padding: 0;
        }
        .page-item {
            margin: 0 5px;

            &.disabled {
                opacity: .5;
                pointer-events: none;
            }
        }
        .page-link {
            font-weight: bold;
            padding: 4px 7px;
            display: block;
            text-align: center;
            background: #f6f6f7;
            color: #4a4a4a;
            border-radius: 4px;
            width: 30px;
            height: 30px;
            border: none;
            line-height: 1.5;
            margin-left: 0;
            box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
            font-size: 14px;
            white-space: nowrap;

            &:hover {
                text-decoration: none;
                color: #4a4a4a;
                box-shadow: none;
                background-color: #d4d4d4;
            }
        }
    }
    .pagination .page-item.active .page-link {
        background: #444960;
        color: #ffffff;
        box-shadow: none;
    }
}
.blog-page.w-100{
    min-height: calc(100vh - 356px);
}

.blog-head{

    @media (min-width: 768px) {
        margin-bottom: 40px;
    }
}

.blog-head-left{
    max-width: 703px;
    width: calc(100% - 535px);
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1199px) {
        width: 100%;
        max-width: inherit;
        margin: 0 auto 25px;
    }

    a {
        width: 100%;

        &:hover {
            text-decoration: none;

            .blog-head-text {
                box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
            }
        }
    }

    img{
        width: 100%;
        height: 27vw;
        max-height: 345px;
        object-fit: cover;
        object-position: center;
        border-radius: 6px 6px 0 0;

        @media (max-width: 1199px){
            height: 41vw;
        }
        @media (max-width: 767px){
            max-height: 191px;
            height: 42vw;
        }
    }

}
.blog-head-text {
    padding: 9px 15px 15px 29px;
    border-radius: 0 0 6px 6px;
    background-color: #F6F6F7;
    color: #000;
    transition: all 0.5s;

    @media (max-width: 767px){
        padding: 15px 15px 10px 15px;
    }
}
.blog-head-title {
    font-size: 1.24em;
    margin-top: 0;
    margin-bottom: 9px;
    line-height: 1.4;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 20px;
        -webkit-line-clamp: 3;
    }
}


.blog-head-right{
    width: 100%;
    max-width: 512px;

    @media (max-width: 1199px) {
        width: 100%;
        max-width: inherit;
    }
}
.blog-popular-item {
    color: #000000;
    margin-bottom: 23px;
    border-radius: 6px;
    transition: all .3s;

    @media (max-width: 767px){
        flex-direction: column;
        border-radius: 6px;
        margin-bottom: 25px;
    }
    &:hover {
        text-decoration: none;
        color: #000000;
        box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
    }
    img {
        width: 280px;
        height: 137px;
        object-fit: cover;
        border-radius: 6px 0 0 6px;

        @media (max-width: 767px){
            width: 100%;
            max-height: 191px;
            height: 42vw;
        }
    }
}

.blog-popular-item-text {
    width: calc(100% - 280px);
    background-color: #F6F6F7;
    padding: 14px 15px 10px 13px;
    display: flex;
    flex-direction: column;
    border-radius: 0 6px 6px 0;

    @media (max-width: 767px) {
        padding: 10px 15px 10px 15px;
        width: 100%;
        border-radius: 0 0 6px 6px;
    }
    h2 {
        font-size: 16px;
        line-height: 1.4;
        overflow: hidden;
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 21px;

        @media (max-width: 767px) {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
    span {
        font-size: 16px;
        margin-top: auto;
    }
}


.blog-container{
    margin-left: -22px;
    margin-right: -22px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1199px) {
        justify-content: center;
        margin-left: -10px;
        margin-right: -10px;
    }
    @media (max-width: 767px){
        align-items: flex-start;
        margin: 0;
    }
}
.blog-block {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    margin-bottom: 44px;
    padding: 0 22px;
    border-radius: 6px;

    &:hover {
        text-decoration: none;
        color: black;

        .blog-text{
            background-color: #FAFAFA;
            box-shadow: 0 1px 1px rgb(0 0 0 / 16%);
        }
    }
    @media (max-width: 1199px){
        width: 50%;
        max-width: 433px;
        padding: 0 10px;
    }
    @media (max-width: 767px) {
        width: 100%;
        position: relative;
        margin-bottom: 25px;
        padding: 0;
    }
}

.blog-block img {
    width: 100%;
    height: 191px;
    object-fit: cover;
    border-radius: 6px 6px 0 0;

    @media (max-width: 991px) {
        height: 22vw;
        max-height: 191px;
    }
    @media (max-width: 767px) {
        height: 42vw;
    }
}
.blog-text {
    padding: 15px 20px 10px 20px;
    height: 150px;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 6px 6px;
    transition: all 0.3s;
    background-color: #fff;
    color: black;
    box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
    text-align: left;

    @media (max-width: 767px){
        padding: 15px 15px 10px 15px;
        height: auto;
    }
}

.blog-block h2 {
    font-size: 20px;
    margin: 0 0 10px 0;
    line-height: 1.4;
    max-height: 140px;
    overflow: hidden;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 20px;
    }
}


.blog-single{
    max-width: 1024px;
    margin: 0 auto;
    padding: 30px 20px 0;

    @media (max-width: 767px){
        padding: 30px 15px 0;
    }
}
.blog-single-img{
    border-radius: 6px;
    max-height: 60vh;
    object-fit: cover;
}

.blog-single-container{
    max-width: 740px;
    padding: 20px 0;
    margin: 10px auto 0;
    font-size: 20px;

    @media (max-width: 767px){
        font-size: 18px;
        margin-top: 0;
    }
    h1{
        font-size: 30px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 25px;

        @media (max-width: 767px){
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    p{
        margin-bottom: 1.5em;
    }
}

.blog-author{
    padding-left: 50px;
    margin-bottom: 60px;
    margin-top: 50px;
    font-size: 16px;

    @media (max-width: 767px) {
        padding-left: 20px;
        margin-bottom: 50px;
    }

    img{
        width: 62px;
        height: 62px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 19px;
    }
    p{
        line-height: 1.5;
    }
    figcaption{

        @media (max-width: 767px) {
            font-size: 14px;
        }
        span{
            color: rgba(0,0,0,.4);
        }
    }
}
.blog_card_image[src=""], .blog_card_image:not([src]) {
    opacity: 0;
}
