
.theme-3-landing-header{
    padding-left: 6vw;
    font-size: clamp(12px, 1.17vw, 18px);
    margin-bottom: 7em;
    position: relative;

    @media (max-width: 991px) {
        padding-left: 4vw;
        font-size: clamp(9px, 1.17vw, 18px);
    }
    @media (max-width: 767px){
        padding: 0;
    }
}
.theme-3-landing-header-text{
    width: 47.8%;
    padding-right: 1em;
    padding-top: 5.4em;

    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        margin-bottom: 5em;
        margin-top: 5em;
        padding: 0 20px;
    }
    h1{
        font-weight: 400;
        margin-bottom: 0.47em;
        font-size: 2.82em;
        line-height: 1.35;

        @media (max-width: 767px){
            font-size: 24px;
            margin-bottom: 0.7em;
        }
    }
    > p{
        margin-bottom: 2.1em;
        font-size: 1.19em;
        line-height: 1.35;
        max-width: 83%;

        @media (max-width: 991px){
            font-size: 1.5em;
        }
        @media (max-width: 767px){
            font-size: 17px;
            max-width: 100%;
        }
    }
}
.theme-3-landing-header-img{
    width: 52.02%;

    @media (max-width: 767px){
        width: 100%;
    }
    > img{
        width: 100%;
        object-fit: cover;
        height: auto;

        @media (max-width: 767px){
            height: auto;
        }
    }
}

.theme-3-landing-2-container{
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto 11rem;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        margin: 0 auto 6rem;
    }
}
.theme-3-landing-2-left{
    width: 53.16%;

    @media (max-width: 767px) {
        width: 100%;
    }
}

.theme-3-landing-2-right{
    width: 44%;
    max-width: 440px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
        margin-bottom: 3rem;
    }
    >  h2{
        font-size: 35px;
        font-weight: 400;
        margin-bottom: .9em;

        @media (max-width: 991px) {
            font-size: 26px;
        }
    }
    > p{
        font-size: 18px;
        letter-spacing: 0.03em;
        line-height: 1.4;
        margin-bottom: 2em;

        @media (max-width: 991px) {
            font-size: 16px;
        }
    }
}


