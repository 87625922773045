
@import 'home';
@import 'information';
@import 'landing';
@import '../../global/tenants-global';


:root {
    --nav-background: #ffffff;
    --nav-link: #000000;
    --nav-link-hover: #2092F3;


    --btn1-background-color: #2092F3;
    --btn1-background-hover-color: #037ce3;
    --btn1-text-color: #ffffff;
    --btn1-text-hover-color: #ffffff;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #2092F3;
    --btn1-outline-text-color: #037ce3;
    --btn1-outline-text-color-hover: #ffffff;
    --btn1-outline-border-color: #2092F3;
    --btn1-outline-border-hover-color: #2092F3;


    --btn2-background-color: #F0AE00;
    --btn2-background-hover-color: #cd9600;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #ffffff;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #F0AE00;
    --btn2-outline-text-color: #000000;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #F0AE00;
    --btn2-outline-border-hover-color: #F0AE00;


    --color-1: #2092F3;
    --color-2: #F0AE00;
}

.btn{
    border-radius: 2em;
}
