@import 'bootstrap';
@import 'variables';
@import 'nav-footer';
@import 'courses';
@import 'quizzes';
@import 'blog';
@import 'contact';
@import 'faq';
@import 'live-lessons';
@import 'slicks';
@import 'toastr';
@import 'rtl';


[dir="rtl"]{
    display: none;
}
.btn[disabled]{
    opacity: .4;
}
#app{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
}
.coming-soon{
    height: 100vh;

    h1{
        font-size: 25px;
        font-weight: normal;
        margin-top: 30px;
    }
}
.coming-soon-text{
    padding-top: 22vh;
    padding-left: 14vh;
    max-width: 50%;

    @media (max-width: 767px) {
        padding-top: 9vh;
        padding-left: 4vh;
        max-width: 95%;
    }
}
.coming-soon-bg{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
}
.overdue{
    height: 100vh;

    @media (max-width: 767px) {
        text-align: center;
    }

    h1{
        font-size: 20px;
        font-weight: normal;
        margin-top: 22px;
        margin-bottom: 33px;
    }
}
.overdue-text{
    padding-top: 22vh;
    padding-left: 14vh;
    max-width: 660px;

    background-color: #ffffffc9;
    box-shadow: 0 0 45px #fff;
    border-radius: 5px;

    @media (max-width: 767px) {
        padding-top: 3vh;
        padding-left: 5px;
        padding-right: 5px;
        margin: 0 auto;
    }
}
.overdue-bg{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;

    @media (max-width: 767px) {
        display: none;
    }
}
.paypal-section{
    min-height: calc(100vh - 312px);

    @media (max-width: 767px){
        flex-direction: column-reverse;
        min-height: auto;
    }
}
.paypal-section-left{
    width: 32%;
    padding: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px){
        width: 100%;
        padding: 40px 20px;
    }
    h1{
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
    }
    #paypal-button-container{
        position: sticky;
        top: 30px;
    }
}
.paypal-section-right{
    background-color: #F4F5F8;
    width: 68%;
    padding: 2vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px){
        width: 100%;
        padding: 20px;
    }
}
.paypal-course-item{
    max-width: 523px;
    color: black;
    padding: 28px;
    background-color: #ffffff;
    margin-bottom: 15px;

    @media (max-width: 991px) {
        padding: 15px;
    }
    @media (max-width: 550px){
        flex-direction: column;
    }
    &:hover{
        color: black;
        text-decoration: none;
    }

    > img{
        width: 134px;
        height: 102px;
        object-fit: cover;

        @media (max-width: 550px){
            margin: 0 auto 10px;
        }
    }
}
.paypal-course-item-info{
    width: calc(100% - 134px);
    padding-left: 20px;

    @media (max-width: 550px){
        width: 100%;
        padding-left: 0;
    }
    h2{
        font-size: 16px;
        font-weight: bold;
        line-height: 1.35;
    }
    table{
        font-size: 14px;
        width: 100%;
        margin-bottom: 10px;

        td{

            &:first-child{
                white-space: nowrap;
            }
            &:last-child{
                text-align: right;
                padding-left: 15px;
                font-size: 16px;
            }
        }
    }
}
.paypal-course-item-price{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #DEDEDE;
    padding-top: 7px;

    b{
        font-size: 18px;
    }
}
.pay-thankyou{
    background-color: #F4F5F8;
    padding: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1{
        font-size: 22px;
        text-align: center;
        margin: 18px 0 42px;
        font-weight: 700;
        color: #0E0E0E;
        line-height: 1.35;
    }
}
.pay-thankyou-courses{
    padding: 60px 10px;
}
.toast-success{
    background-color: var(--btn1-background-color);
    color: var(--btn1-text-color);
}
.page-404{
    height: 100vh;
    padding: 30px 0;
}
.text-404{
    padding-left: 5vw;
    width: 49%;

    @media (min-width: 1500px) {
        padding-left: 10vw;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 0 20px;
    }
    h1{
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 20px;

        @media (min-width: 1500px){
            font-size: 40px;
        }
    }
    p{
        font-size: 18px;
        margin-bottom: 40px;
    }
}
.img-404{
    width: 51%;

    @media (max-width: 767px) {
        width: 100%;
    }
}
.home-course-section.home-course-section-slider{
    max-width: 1230px;

    @media (max-width: 1120px){
        max-width: 780px;
    }
    .home-course-section-container:not(.slick-slider){
        padding-bottom: 0;
    }
    .home-course-item{
        text-align: left;
    }
}

.home-course-section-container.slick-slider{
    margin: 0 auto;
    padding: 40px;

    @media (max-width: 1120px){
        padding: 30px 0;
    }
    .slick-prev, .slick-next{
        width: 30px;
        height: 30px;
    }
    .slick-next {
        right: 6px;
    }
    .slick-prev {
        left: 4px;
    }
    .slick-slide{
        height: auto;
    }
    .home-course-item{
        margin-bottom: 4px;
    }
}
.background-landing {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.edit-page-builder {
    position: fixed;
    top: 100px;
    right: 50px;
    z-index: 99999;
}
.cookie-section {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 10px;
    width: 98%;
    max-width: 1186px;
    margin: 0 auto;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    background: #fff;
    z-index: 8;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@media (max-width: 991px) {
    .cookie-section {
        padding: 15px 20px;
    }
}
@media (max-width: 767px) {
    .cookie-section {
        flex-wrap: wrap;
        padding-top: 40px;
    }
}
.cookie-section > img {
    margin-right: 20px;
}
@media (max-width: 767px) {
    .cookie-section > img {
        display: none;
    }
}
@media (max-width: 991px) {
    .cookie-section p {
        font-size: 14px;
        line-height: 1.4;
    }
}
@media (max-width: 767px) {
    .cookie-section p {
        margin-bottom: 10px;
    }
}
.cookie-section .btn {
    border-radius: 6px;
    border: 1px solid #c4c4d2;
    margin-left: 12px;
    white-space: nowrap;
    font-size: 14px;
    padding: 9px 17px;
    color: #000000;
    margin-bottom: 8px;
}
.cookie-section .btn:hover {
    background-color: #f1f1f1;
}
.cookie-section .btn-close {
    padding: 3px 10px;
    border: none;
    background-color: #ffffff;
    margin-left: 25px;
    transition: transform 0.3s;
    position: absolute;
    top: -30px;
    right: 15px;
    border-radius: 4px 4px 0 0;
}
.cookie-section .btn-close:hover {
    transform: scale(1.2);
}
@media (max-width: 1500px) {
    .edit-page-builder {
        display: none;
    }
}
@media (max-width: 920px) {
    .gjs-lory-frame, .gjs-lory-prev, .gjs-lory-next {
        display: none!important;
    }
}



.products-container-wrapper {
    background: #F4F5F8 0 0 no-repeat padding-box;

    .products-container {

        .oneItem {
            padding-left: 15px;
            padding-right: 15px;
            max-width: 550px;
            width: 100%;
            margin: 15px auto;
            position: relative;

            .imageBox {
                width: 198px;
                height: 350px;
                flex: 0 0 198px;
                background: #F4F5F8 0 0 no-repeat padding-box;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 767px) {
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }

        .detailsBox {
            background: #FFFFFF 0 0 no-repeat padding-box;
            padding: 15px;
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            text-align: left;

            .title {
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0;
                color: #000000;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .description {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .priceBox {
                .price {
                    font-size: 20px;
                    line-height: 30px;
                    color: #000000;
                }

                .old_price {
                    font-size: 20px;
                    line-height: 30px;
                    color: #FF7F7F;
                }
            }

            .footer {
                margin-top: auto;
            }
        }
    }
}
.product-container-wrapper {

    .imageBox {

        .top {
            border: 1px solid #D6D7D9;
            border-radius: 3px;

            .sliderItem {
                width: 353px;
                height: 529px;
                border-radius: 3px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .bottom {
            max-width: 350px;
            margin: 0 auto;
            width: 100%;

            .sliderItem {
                width: 63px;
                height: 94px;
                border: 1px solid #D6D7D9;
                border-radius: 3px;
                margin: 8px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .detailsBox {
        .title {
            text-align: left;
            font-size: 30px;
            line-height: 46px;
            letter-spacing: 0;
            color: #0A0A0A;
        }

        .author {
            text-align: left;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0;
            color: #0A0A0A;
        }

        .priceBox {
            .priceText {
                font-size: 16px;
                line-height: 26px;
                color: #888888;
            }
            .price {
                font-size: 20px;
                line-height: 30px;
                color: #000000;
            }

            .old_price {
                font-size: 20px;
                line-height: 30px;
                color: #FF7F7F;
            }
        }

        .descriptionText {
            margin: 15px 0;
            font-size: 18px;
            line-height: 27px;
        }
    }

    .video-wrapper {
        width: 100%;
        border-width: 1px;
        border-color: #D6D7D9;
        border-style: solid;
        border-radius: 3px;
        text-align: center;
        padding: 7px 0;

        .video-btn {
            padding: 10px 18px 10px 50px;
            border: 1px solid #D6D7D9;
            border-radius: 3px;
            background-color: #F8F8F8;
            display: inline-block;
            position: relative;
            font-size: 16px;
            color: #0A0A0A;

            svg {
                position: absolute;
                left: 19px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .promo-video {
        max-width: 100%;
        width: 100%;
        height: 100%;
    }
}
.similarProducts {
    h3 {
        font-size: 20px;
        margin-top: 37px;
        margin-bottom: 29px;
    }
}
.inStock {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0;
    color: #45C9A5;

    &.unavailable {
        color: #ccc;
    }
}
.productCheckoutTitle {
    font-size: 25px;
    margin-top: 38px;
    font-weight: 600;
}
.checkout-container {
    max-width: 1280px;
    padding-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    width: 100%;
}
.borderBox {
    border-right: 2px solid #fff;
}
.btn-download {
    border: none;
    position: absolute;
    right: 7px;
    bottom: 21px;
    background-color: transparent;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    color: #414141;
    transition: all 0.2s;
    padding: 13px;
    outline: none;
    border-radius: 0 0 15px 0;
}
.full-screen-overlay{
    overflow: hidden;
}
.full-screen-editor{
    position: fixed !important;
    top:0;
    left: 0;
    right:0;
    bottom: 0;
    z-index:1000;
}
.full-screen-editor .ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.full-screen-editor .ck.ck-editor__main,
.full-screen-editor .ck.ck-editor__main .ck-source-editing-area {
    height: 100%;
    max-height: none;
}

.courses-search-form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 42px;
    height: 42px;
    overflow: hidden;
    transition: 0.2s;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    flex: 0 0 42px;
}
.courses-search-form.show {
    width: 300px;
    flex: 0 0 300px;
    border-color: #c2c2c2;

    @media (max-width: 520px){
        flex: 0 0 auto;
    }
}
.courses-search-form button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 50%;
    padding: 5px;

    svg{
        width: 18px;
    }
}
.courses-search-form.show button {
    display: none;
}
.courses-search-form input {
    position: absolute;
    right: 0;
    height: 40px;
    width: 100%;
    z-index: 1;
    pointer-events: auto;
    user-select: auto;
    background-color: #fff;
    border: none;
    padding-left: 37px;
    padding-right: 30px;
}
.courses-search-form input:focus {
    outline: none;
}
.courses-container .courses-search-form-wrapper h1:not(.empty-title) {
    margin-bottom: 0;
}
.courses-container .courses-search-form-wrapper {
    margin-bottom: 40px;
}

.input-wrapper {
    position: relative;
    width: 0;
    height: 40px;
}
.input-wrapper svg {
    position: absolute;
    left: 5px;
    width: 25px;
    top: 6px;
    z-index: 3;
    display: none;
}
.courses-search-form.show .input-wrapper {
    width: 100%;
    display: block;
}
.courses-search-form.show .input-wrapper svg {
    display: block;
}

@media (max-width: 520px) {
    .courses-search-form-wrapper {
        flex-direction: column;
    }

    .courses-search-form-wrapper .courses-search-form {
        margin-top: 20px;
    }

    .live-section h1, .products-page-title {
        text-align: center;
    }
}

.live-section h1 {
    margin-bottom: 0;
}



