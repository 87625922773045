.theme-3-header{
    font-size: clamp(12px, 1.17vw, 18px);
    max-width: 93%;
    margin: 0 auto 5rem;
    padding: 2.8vw 3.3vw 0;

    @media (max-width: 991px) {
        font-size: clamp(9px, 1.17vw, 18px);
        max-width: 100%;
        padding: 8vw 3.3vw 0;
    }
    @media (max-width: 767px){
        padding: 0;
    }
}
.theme-3-header-text{
    width: 42.6%;
    padding-right: 1.6em;
    margin-right: auto;

    @media (max-width: 991px){

        > .btn{
            font-size: 14px;
        }
    }
    @media (max-width: 767px){

        > .btn{
            font-size: 16px;
        }
    }
    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        margin-bottom: 5em;
        margin-top: 4em;
        padding: 0 20px;
    }
    h1{
        font-weight: 400;
        margin-bottom: 0.55em;
        font-size: 2.2em;
        line-height: 1.35;

        @media (max-width: 767px){
            font-size: 24px;
            margin-bottom: 0.8em;
        }
    }
    > p{
        margin-bottom: 2.1em;
        font-size: 1.185em;
        line-height: 1.35;

        @media (max-width: 991px){
            font-size: 1.5em;
        }
        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}
.theme-3-header-img{
    width: 46.81%;

    @media (max-width: 767px){
        width: 100%;
    }
    > img{
        width: 100%;
        object-fit: cover;

        @media (max-width: 767px){
            height: auto;
        }
    }
}


.theme-3-section-2{
    margin-bottom: 4.81em;

    @media (max-width: 767px){
        margin-bottom: 3.6em;
    }
}
.theme-3-section-3{
    margin-bottom: 4.42rem;

    @media (max-width: 767px){
        margin-bottom: 3.12rem;
    }
    > h2{
        margin-bottom: 2em;
        font-size: 1.88em;
        font-weight: 400;

        @media (max-width: 767px){
            margin-bottom: 1.5em;
            font-size: 1.4em;
        }
    }
}
.theme-3-section-3-container{
    margin: 0 auto;
    max-width: 1115px;
    padding: 0 15px;
}
.theme-3-section-3-item{
    width: 33.33%;
    padding: 20px 27px 30px;
    margin-bottom: 30px;
    position: relative;
    border-radius: 28px;

    @media (max-width: 991px) {
        padding: 20px 15px 30px;
    }
    @media (max-width: 767px) {
        width: 100%;
        max-width: 400px;
        text-align: center;
    }
    &:after{
        content: "";
        background: rgb(28,142,244);
        background: linear-gradient(0deg, rgba(28,142,244,1) 0%, rgba(121,227,237,1) 100%);
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        z-index: -1;
        transition: all .3s;
        border-radius: 28px;
    }
    &:hover{
        color: #ffffff;

        &:after{
            opacity: 1;
            height: 100%;
        }
    }

    > img{
        width: auto;
        max-width: 150px;
        height: 85px;
        border-radius: 50%;
        margin-bottom: 20px;
        object-fit: cover;
    }
    > h3{
        font-size: 19px;
        margin-bottom: .9em;

        @media (max-width: 991px){
            font-size: 18px;
        }
    }
    > h4{
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 1.15em;
    }
    > p{
        font-size: 18px;
        line-height: 1.35;
        margin-bottom: 0;

        @media (max-width: 991px){
            font-size: 16px;
        }
        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}


.theme-3-section-4-container{
    max-width: 1165px;
    padding: 0 20px 0;
    margin: 0 auto 6.25rem;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        margin: 0 auto 3.15rem;
    }
}
.theme-3-section-4-left{
    width: 53.15%;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
    }
}
.theme-3-section-4-left-img{
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}
.theme-3-section-4-right{
    width: 45%;
    margin-left: auto;
    max-width: 459px;
    padding-top: 42px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
        padding-top: 0;
    }
    > h2{
        font-size: 1.88em;
        font-weight: 400;
        margin-bottom: 2em;

        @media (max-width: 767px){
            text-align: center;
            font-size: 1.4em;
            margin-bottom: 1.5em;
        }
    }
    > p{
        font-size: 18px;
        letter-spacing: 0.03em;
        line-height: 1.4;
        margin-bottom: 1em;

        @media (max-width: 991px) {
            font-size: 16px;
        }
    }
}


.theme-3-section-5{
    margin-bottom: 6.25rem;

    @media (max-width: 767px) {
        margin-bottom: 3.15rem;
    }

    > h2{
        margin-bottom: 2em;
        font-size: 1.88em;
        font-weight: 400;

        @media (max-width: 767px){
            margin-bottom: 1.5em;
            font-size: 1.4em;
        }
    }
}
.theme-3-section-5-container{
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1240px;

    @media (max-width: 991px){
        max-width: 600px;
    }
}
.theme-3-section-5-item{
    width: 25%;
    padding: 0 20px;
    margin-bottom: 30px;

    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 767px) {
        padding: 0 10px;
    }
    > img{
        width: 211px;
        height: 211px;
        border-radius: 50%;
        margin-bottom: 15px;
        object-fit: cover;

        @media (max-width: 991px){
            width: 150px;
            height: 150px;
        }
        @media (max-width: 767px){
            width: 140px;
            height: 140px;
        }
    }
    > h3{
        font-size: 20px;
        margin-bottom: .3em;

        @media (max-width: 991px){
            font-size: 18px;
        }
    }
    > p{
        font-size: 14px;
        line-height: 1.35;
        margin-bottom: 0;

        @media (max-width: 991px){
            font-size: 14px;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
    }
}

.theme-3-section-6{
    margin: 0 0 1em;
    padding: 0 20px;

    > h2{
        margin-bottom: 2.5em;
        font-size: 1.88em;
        font-weight: 400;

        @media (max-width: 767px){
            font-size: 1.4em;
        }
    }
}
.theme-3-section-6-container{
    margin: 0 auto;
    max-width: 1380px;
}
.theme-3-section-6-item{
    width: calc(50% - 30px);
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
    padding: 20px;
    border-radius: 60px 0 60px 0;
    text-align: center;
    margin: 15px 15px 50px;

    @media (max-width: 767px) {
        width: 100%;
        margin: 15px 0 50px;
        padding: 20px 15px;
    }
    &:nth-child(1){
        border-radius: 60px 0 60px 0;
    }
    &:nth-child(2){
        border-radius: 0 60px 0 60px;
    }
    &:nth-child(3){
        border-radius: 0 60px 0 60px;
    }
    &:nth-child(4){
        border-radius: 60px 0 60px 0;
    }
    &:hover{
        background-color: #F3F8FF;
    }
    > img{
        width: 79px;
        height: 79px;
        border-radius: 50%;
        margin-top: -60px;
        margin-bottom: 8px;
        box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
        border: solid 1px #ffffff;
    }
    > h3{
        font-size: 20px;
    }
    > h4{
        font-size: 14px;
        margin-bottom: 25px;
    }
    > p{
        font-size: 16px;
    }
}



//video course
:root {
    --course-background: #ffffff;
    --course-background-hover: #ffffff;
    --course-color: #1D1D1D;
    --course-color-hover: #1D1D1D;
    --course-color-hr: #e4e8f2;
    --course-color-hr-hover: #e4e8f2;
}
.home-course-section{
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    z-index: 1;

    > h2{
        margin-bottom: 1em;
        font-weight: 400;
        font-size: 2.2em;
        padding-top: 32px;

        @media (max-width: 991px){
            font-size: 1.6em;
        }
        @media (max-width: 767px){
            margin-bottom: .5em;
        }
    }
}
.home-course-section-container{
    padding-bottom: 30px;
    padding-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;
    justify-content: center;
}
.home-course-item-block{
    width: 370px;
    padding: 0 15px;
    transition: color .3s;

    &:hover{
        color: #1D1D1D;
        text-decoration: none;

        .home-course-author, .home-course-info-block, .home-course-price{
            background-color: var(--course-background-hover);
            color: var(--course-color-hover);
        }
        .home-course-item{
            box-shadow: 0 2px 6px #0000000f;
        }
        hr{
            border-color: var(--course-color-hr-hover);
        }
    }
    hr{
        margin: 0;
        border-color: var(--course-color-hr);
        transition: all .3s;
    }
    @media (max-width: 390px){
        width: 345px;
    }
}
.home-course-item {
    width: 100%;
    color: #1D1D1D;
    box-shadow: 0 2px 6px #00000029;
    display: block;
    border-radius: 16px;
    margin: 0 0 23px;
    position: relative;
    transition: all .3s;
}
.home-course-item-img{
    border-radius: 16px 16px 0 0;
    height: 166px;
    width: 100%;
    object-fit: cover;
}
.home-course-author{
    background-color: var(--course-background);
    color: var(--course-color);
    padding: 8px 20px;
    display: flex;
    align-items: center;
    transition: background-color .3s;

    img{
        width: 38px;
        height: 38px;
        object-fit: cover;
        margin-right: 8px;
        border-radius: 50%;
    }
    span{
        font-size: 14px;
        font-weight: 500;
        font-family: NotoSansArmenianBold,Poppins,Open Sans,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
    }
}
.home-course-info-block{
    background-color: var(--course-background);
    color: var(--course-color);
    padding: 10px 20px;
    height: 173px;
    transition: background-color .3s;

    h2{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-family: NotoSansArmenianBold,Poppins,Open Sans,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
    p{
        font-size: 14px;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0;
    }
}
.home-course-price{
    background-color: var(--course-background);
    color: var(--course-color);
    transition: background-color .3s;
    padding: 11px 20px;
    border-radius: 0 0 16px 16px;
    line-height: 1.2;
    height: 48px;

    @media (max-width: 400px){
        padding: 10px 15px;
    }
    p{
        font-size: 14px;
        padding-right: 10px;

        @media (max-width: 358px){
            font-size: 12px;
        }
    }
    span{
        font-size: 20px;
        font-weight: 700;

        @media (max-width: 400px){
            font-size: 18px;
        }
        @media (max-width: 358px){
            font-size: 16px;
        }
        del{
            font-size: 16px;
            opacity: .8;
            margin-right: 12px;
            margin-bottom: 3px;
            display: inline-block;

            @media (max-width: 400px){
                font-size: 14px;
            }
            @media (max-width: 358px){
                margin-right: 7px;
                margin-bottom: 0;
            }
        }
    }
}
//end video course




