.color-1{
    color: var(--color-1);
}
.color-2{
    color: var(--color-2);
}



.no-wrap{
    white-space: nowrap;
}

.btn{
    border-radius: 0.4em;
    font-size: 16px;
    padding: 0.695em 1.55em;
    letter-spacing: .034em;

    &:focus{
        box-shadow: none;
    }
    &[disabled]{
        opacity: .6;
        pointer-events: none;
    }
}
.btn-sm{
    font-size: 14px;
    letter-spacing: 0;
}

.btn.btn-1{
    background-color: var(--btn1-background-color);
    color: var(--btn1-text-color);

    &:hover{
        background-color: var(--btn1-background-hover-color);
        color: var(--btn1-text-hover-color);
    }
}
.btn.btn-1-outline{
    background-color: var(--btn1-outline-background-color);
    color: var(--btn1-outline-text-color);
    border-color: var(--btn1-outline-border-color);

    &:hover{
        background-color: var(--btn1-outline-background-hover-color);
        color: var(--btn1-outline-text-color-hover);
        border-color: var(--btn1-outline-border-hover-color);
    }
}
.btn.btn-2{
    background-color: var(--btn2-background-color);
    color: var(--btn2-text-color);

    &:hover{
        background-color: var(--btn2-background-hover-color);
        color: var(--btn2-text-hover-color);
    }
}
.btn.btn-2-outline{
    background-color: var(--btn2-outline-background-color);
    color: var(--btn2-outline-text-color);
    border-color: var(--btn2-outline-border-color);

    &:hover{
        background-color: var(--btn2-outline-background-hover-color);
        color: var(--btn2-outline-text-color-hover);
        border-color: var(--btn2-outline-border-hover-color);
    }
}

.button-link{
    background-color: transparent;
    border: none;
}

.custom-checkbox-glogal{
    margin-bottom: 1.17em;

    input:not(.other-option) {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    input + label {
        position: relative;
        display: inline-block;
        padding: 0 0 0 2em;
        line-height: 1.25;
        cursor: pointer;
        margin-bottom: 0;
        font-size: 1rem;
        color: #444960;
    }

    input + label::before,
    input + label::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        z-index: 1;
    }

    input + label::before {
        content: " ";
        border: 1px solid #AEB5BD;
        border-radius: 3px;
        width: 1.278em;
        height: 1.278em;
        background-color: #ffffff;
    }

    input + label::after {
        content: " ";
        border: .23em solid #444960;
        border-top: none;
        border-right: none;
        line-height: 1.5;
        height: 0.45em;
        left: 0.25em;
        top: 0.35em;
        width: .85em;
        border-radius: 1px;
    }

    /* :checked */
    input:checked + label::before {
        border-color: #444960;
    }

    input + label::after {
        transform: scale(0);
    }

    input:checked + label::after {
        transform: scale(1) rotate(-45deg);
    }

    /* Transition */
    label::before,
    label::after {
        transition: 0.25s all ease;
    }
}
.custom-radio-glogal{
    margin-bottom: 1.17em;

    input[type=radio] {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    input[type=radio] + label {
        position: relative;
        display: inline-block;
        padding: 0 0 0 2em;
        line-height: 1.25;
        cursor: pointer;
        margin-bottom: 0;
        font-size: 1rem;
        color: #444960;
    }

    input[type=radio] + label::before,
    input[type=radio] + label::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
    }

    input[type=radio] + label::before {
        content: " ";
        border: .1em solid #AEB5BD;
        width: 1.278em;
        height: 1.278em;
        border-radius: 50%;
        background-color: #ffffff;
    }

    input[type=radio] + label::after {
        content: " ";
        line-height: 1.5;
        height: calc(.866em - .1em);
        left: .28em;
        top: .26em;
        width: calc(.866em - .1em);
        border-radius: 50%;
        background-color: #444960;
    }

    /* :checked */
    input[type=radio]:checked + label::before {
        border-color: #444960;
    }

    input[type=radio] + label::after {
        transform: scale(0);
    }

    input[type=radio]:checked + label::after {
        transform: scale(1);
    }

    /* Transition */
    label::before,
    label::after {
        transition: 0.25s all ease;
    }
}
.custom-select{
    background-size: 13px 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.963' height='9.001' viewBox='0 0 13.963 9.001'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23abadb7;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(305.927 -1.976) rotate(90)'%3E%3Cpath class='a' d='M3.035,291.965a.993.993,0,0,0-.654,1.759l6.077,5.206-6.077,5.2a.993.993,0,1,0,1.29,1.5l6.957-5.952a.993.993,0,0,0,0-1.511l-6.957-5.958a.992.992,0,0,0-.636-.251Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/svg%3E");
}
.loader {
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #0000007a;
    z-index: 100000;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;

    .spinner-border {
        width: 3.5rem;
        height: 3.5rem;
    }

    &.show {
        display: flex;
    }
}
.customFBSignIn, .customGPlusSignIn {

    .buttonText {
        padding: 2px 3px;
        background-color: #4267B2;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
    }

    img {
        width: 10px;
    }
}
.customGPlusSignIn {

    .buttonText{
        background-color: #ffffff;
        border: solid 1px #ebebeb;
    }
    img {
        width: 25px;
    }
}
.have-account{
    font-size: 14px;
    margin-top: 25px;
}
.login-modal{

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    input:-webkit-autofill::first-line {
        font-size: 1rem;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
    .modal-header{
        border: none;
        padding-bottom: 0;
    }
    .modal-dialog{
        max-width: 455px;
    }
    .modal-body{
        max-width: 420px;
        margin: 0 auto;
        width: 100%;
        padding-bottom: 30px;

        h2{
            font-size: 23px;
            font-weight: normal;
            margin-bottom: 35px;

            @media (max-width: 575px) {
                margin-bottom: 25px;
                font-size: 22px;
            }
        }
    }
    .close{
        position: absolute;
        right: 27px;
        top: 18px;
        z-index: 1;
        font-size: 30px;
        padding: 5px 10px;
    }
    .custom-checkbox-glogal{
        margin-bottom: 0;

        label{
            margin-bottom: 0;
        }
    }
    .form-group{
        margin-bottom: 15px;

        label{
            margin-bottom: 6px;
            color: black;
            font-size: 15px;

            &.form-check-label{
                padding-left: 23px;
                font-size: 14px;

                @media (max-width: 380px) {
                    font-size: 12px;
                }
            }
        }
    }
    .form-control{
        border-radius: 23px;
        height: auto;
        min-height: 45px;
        padding-left: 20px;
    }
    .btn-link{
        color: black;
        font-size: 14px;

        @media (max-width: 380px) {
            font-size: 12px;
        }
    }
}
.text-in-line{
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: 15px;
    color: #7b7b7b;
    width: 140px;
    margin: 25px auto 20px;

    @media (max-width: 575px){
        margin: 20px auto 20px;
    }

    &:before{
        border-top: 1px solid #7b7b7b;
        content: "";
        margin: 0 auto;
        position: absolute;
        inset: 52% 0 0;
        width: 50%;
        z-index: -1;
    }
    span{
        background: white;
        padding: 0 9px;
    }
}
.toggle-password{
    position: absolute;
    right: 15px;
    top: 41px;
    z-index: 1;
    cursor: pointer;
    width: 21px;
    height: 20px;
    opacity: .5;

    img{
        width: 20px;
    }
    &.eye-slash{

        .d-none{
            display: block!important;
        }
        .d-block{
            display: none!important;
        }
    }
}
.was-validated .form-control:invalid, .form-control.is-invalid{
    background-image: none;
}
.empty-title{
    padding: 10vh 10px;
    font-size: 26px
}

.preview-info{
    background-color: var(--btn1-background-color);
    color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90%;
    max-width: 1200px;
    z-index: 8;
    text-align: center;
    padding: 12px 40px;
    border-radius: 8px 8px 0 0;

    span{
        position: absolute;
        right: 20px;
        top: 11px;
        cursor: pointer;
    }
}
audio:focus-visible{
    outline: none;
}
