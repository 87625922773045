



html[dir="rtl"]{
    display: block;

    body{
        text-align: inherit;
    }
    .text-left, .dropdown-menu, .classes-accordion .card-header .btn {
        text-align: right;
    }
    .text-right{
        text-align: left;
    }
    .mr-auto{
        margin-left: auto;
        margin-right: 0;
    }
    .ml-auto {
        margin-right: auto;
        margin-left: 0;
    }
    .ml-1 {
        margin-left: 0;
        margin-right: 0.25rem;
    }
    .mr-2 {
        margin-left: 0.5rem;
        margin-right: 0;
    }
    .pl-4{
        padding-left: 0;
        padding-right: 1.5rem;
    }
    .navbar-brand{
        margin-left: 1rem;
        margin-right: 0;
    }
    .links-group > .nav-link:first-child{
        padding-right: 6px !important;
        padding-left: 0;
    }
    .navbar-nav .nav-item-user{
        margin-left: 0;
        margin-right: 15px;
    }
    .footer-links{
        padding: 0;
    }
    .dropdown-menu-right{
        right: inherit;
        left: 0;
    }
    .toggle-password{
        left: 15px;
        right: inherit;
    }


    .basic-header{
        padding-right: 9vw;
        padding-left: 0;
    }
    .basic-head-title-img{
        left: inherit;
        right: 0;
    }
    .basic-header-text{
        padding-left: 2.6em;
        padding-right: 1.6em;
    }
    .basic-section-2{
        padding-left: 9vw;
        padding-right: 0;
    }
    .basic-section-2-right{
        margin-right: auto;
        margin-left: 0;
    }
    .basic-section-2-block{
        margin-right: auto;
        margin-left: 0;
    }
    .section-2-basic-oval{
        left: inherit;
        right: 0;
    }
    .home-course-author img{
        margin-right: 0;
        margin-left: 8px;
    }
    .basic-section-5-item-author img{
        margin-right: 0;
        margin-left: 13px;
    }
    .home-course-price p{
        padding-right: 0;
        padding-left: 10px;
    }
    .basic-landing-header{
        padding-right: 9vw;
        padding-left: 0;

        @media (max-width: 767px){
            padding: 0 20px;
        }
    }
    .basic-landing-header-text{
        padding-left: 1.6em;
        padding-right: 0;

        @media (max-width: 767px){
            padding-left: 0;
        }
    }
    .basic-landing-2-left{
        padding: 8.8% 11.5% 13% 0;
    }
    .basic-landing-2-right{
        margin-left: 0;
        margin-right: auto;
    }
    .basic-landing-3-right{
        padding: 8.8% 0 13% 11.5%;
    }
    .basic-landing-3-left{
        margin-left: auto;
        margin-right: 0;
    }
    .quize-list .slick-slider .slick-prev{
        right: -15px;
        left: auto;
    }
    .quize-list .slick-slider .slick-next{
        right: auto;
        left: -15px;
    }
    .first-live-item-right{
        border-left: none;
        border-right: solid 1px #E3E3E3;
        padding-left: 0;
        padding-right: 12px;
    }
    .first-live-item-left{
        padding-left: 9px;
        padding-right: 0;
    }
    .live-show-head-img:after{
        transform: rotate(180deg);
    }
    .live-show-right .icon-live{

        @media (max-width: 767px){
            margin-right: 0;
            margin-left: 15px;
        }
    }
    .icon-live-circle{
        margin-right: 0;
        margin-left: 6px;
    }
    .live-courses-included .faq-accordion .card-header .btn::after{
        right: inherit;
        left: 15px;
    }
    .live-courses-included .faq-accordion .card-header .btn > .card-title{
        padding-left: 55px;
        padding-right: 16px;
    }
    .included-live-show-day-mount{
        padding-right: 10px;
        padding-left: 5px;
        border-right: none;
        border-left: solid 5px #ffffff;
    }
    .live-spekars figure figcaption{
        padding-left: 0;
        padding-right: 32px;

        @media (max-width: 575px){
            padding-right: 0;
        }
    }
    .faq-accordion .card-header .btn{
        text-align: right;
    }
    .faq-accordion .card-header .btn span{
        padding-left: 40px;
        padding-right: 0;
    }
    .faq-accordion .card-header .btn:after{
        right: inherit;
        left: 0;
    }
    .live-courses-included .faq-accordion .card-header .btn > .card-title span{
        padding-left: 0;
    }
    .free-info{
        left: 10px;
        right: inherit;
    }
    .course-author img{
        margin-left: 6px;
        margin-right: 0;
    }
    .course-price span del{
        margin-left: 12px;
        margin-right: 0;
    }
    .course-single-head-left .star-block{
        padding-right: 0;
    }
    .course-single-head-left{
        padding: 20px 11vw 20px 20px;

        @media (max-width: 1199px){
            padding: 20px 50px 20px 20px;
        }
        @media (max-width: 767px){
            padding: 20px 20px 20px 20px;
        }
    }
    .classes-accordion .card-header .btn:after{
        right: 12px;
        left: inherit;
    }
    .accordion-button .btn{
        text-align: right;
    }
    .accordion-button .btn .menu-arrow{
        left: 12px;
        right: inherit;
    }
    .accordion-button .btn .section-title{
        padding-left: 30px;
        padding-right: 0;
    }
    .classes-accordion .card-header .btn{
        padding: 0.8rem 43px 0.8rem 1.2rem;

        @media (max-width: 767px){
            padding: 20px 30px 20px 10px;
        }
    }
    .episode-title{
        padding-right: 0;
        padding-left: 15px;

        @media (max-width: 767px){
            margin-left: auto;
            margin-right: 0;
            padding-left: 0;
            padding-right: 10px;
        }
    }
    .episode-count{
        margin-right: 0;
        margin-left: 10px;

        @media (max-width: 767px){
            left: 10px;
            right: inherit;
            margin-left: 0;
        }
    }
    .episode-time{
        text-align: left;

        @media (max-width: 767px){
            left: 10px;
            right: inherit;
        }
    }
    .classes-accordion .card-body{
        padding: 10px 43px 10px 16px;

        @media (max-width: 575px){
            padding: 8px 12px 8px 10px;
        }
    }
    .classes-accordion .card-body > img{
        margin-left: 15px;
        margin-right: 0;
    }
    .classes-accordion .card-body .episode-time{
        margin-left: 13px;
        margin-right: 0;
    }
    .classes-accordion .card-body .episode-title{

        @media (max-width: 575px){
            padding-left: 12px;
            padding-right: 0;
        }
    }
    .blog-author img{
        margin-right: 0;
        margin-left: 19px;
    }
    .print-down a{
        margin-left: 0;
        margin-right: 20px;
    }
    .print-down a img{
        margin-right: 0;
        margin-left: 5px;
    }
    .secondary-contact-bg{
        left: 2vw;
        right: inherit;

        @media (max-width: 1199px){
            left: 0;
        }
    }

    .profile-menu{
        left: inherit;
        right: 0;
    }
    .profile-container{
        margin-right: auto;
        margin-left: 0;

        @media (max-width: 767px){
            padding-right: 65px;
            padding-left: 15px;
        }
    }
    .profile-nav-link{

        @media (max-width: 767px){
            padding: 11px 14px 11px 11px;
        }
    }
    .profile-nav-link img, .profile-nav-link svg{
        margin-left: 20px;
        margin-right: 0;

        @media (max-width: 1240px){
            margin-left: 15px;
        }
    }
    .open-menu-filter:after{
        transform: rotate(-45deg);
        right: 14px;
    }
    .profile-menu-open .open-menu-filter:after{
        transform: rotate(135deg);
    }
    .nav-tabs .nav-item .nav-link.mr-5{
        margin-right: 0;
        margin-left: 3rem;
    }
    .edit-img img{
        margin-left: 7px;
        margin-right: 0;
    }
    .btn-rating img {
        margin-right: 0;
        margin-left: 7px;
    }
    .modal-header .close{
        margin: -1rem auto -1rem -1rem;
    }
    .video-info-block{
        right: inherit;
        left: -1px;
    }
    .correct-answer:after{
        left: 10px;
        right: inherit;
    }
    .play-ul li a > img{
        margin-left: 10px;
        margin-right: 0;
    }
    .play-ul li a .play-title{
        margin-left: 8px;
        margin-right: 0;
        text-align: right;
    }
    .play-ul li a .play-time{
        margin-left: 20px;
        margin-right: 0;
    }
    .play-ul .free-episode{
        right: inherit;
        left: 0;
    }
    .openCloseToggle{
        left: 395px;
        right: inherit;
        margin-left: 0;
        margin-right: -30px;
        border-radius: 0 6px 6px 0;
    }
    .video-info-block.closed{
        left: -380px;
    }
    .openCloseToggle.closed{
        left: 0;
    }
    .btn-back{
        direction: ltr;
    }
    body.position_mode_bottom .openCloseToggle.closed{
        left: 50%;
        right: auto;
    }

    .theme-3-landing-header{

        @media (min-width: 768px){
            padding-right: 6vw;
            padding-left: 0;
        }
    }
    .theme-3-landing-header-text{

        @media (min-width: 768px){
            padding-left: 1em;
            padding-right: 0;
        }
    }
    .theme-3-header-text{

        @media (min-width: 768px){
            margin-left: auto;
            margin-right: 0;
            padding-left: 1.6em;
            padding-right: 0;
        }
    }
    .theme-3-section-4-right{

        @media (min-width: 768px){
            margin-left: 0;
            margin-right: auto;
        }
    }
    .theme-4-feedback-right{

        @media (min-width: 768px){
            padding-right: 30px;
            padding-left: 0;
        }
    }
    .theme-4-feedback-right-item{

        @media (min-width: 768px){
            padding-right: 57px;
            padding-left: 0;
        }
    }
    .theme-4-feedback-left{

        @media (min-width: 768px){
            padding-right: 112px;
            padding-left: 0;
        }
    }
    .theme-4-feedback-blue{

        @media (min-width: 768px){
           right: 0;
            left: inherit;
        }
    }
    .theme-4-trener-bg{
        left: 0;
    }
    .theme-4-landing-header-text{

        @media (min-width: 768px){
            padding: 0 8vw 0 20px;
        }
        @media (min-width: 768px) and (max-width: 991px){
            padding: 0 5vw 0 20px;
        }
    }
    .theme-5-header{

        @media (min-width: 768px){
            padding-right: 13.75vw;
            padding-left: 0;
        }
        @media (min-width: 768px) and (max-width: 1280px){
            padding-right: 70px;
        }
    }
    .theme-5-header-text{

        @media (min-width: 768px){
            padding-left: 1.6em;
            padding-right: 0;
            margin-left: auto;
            margin-right: 0;
        }
    }
    .theme-5-section-4-img{

        @media (min-width: 768px){
            border-radius: 0 19.5vw;
        }
    }
    .theme-5-section-4-text{

        @media (min-width: 768px){
            border-radius: 0 0 19.5vw;
        }
    }
    .theme-5-section-6-item-user-name{

        @media (min-width: 768px){
            padding-left: 0;
            padding-right: 20px;
        }
    }
    .theme-5-landing-header-text, .theme-6-landing-header-text{

        @media (min-width: 768px){
            padding: 0 8vw 0 20px;
        }
        @media (min-width: 768px) and (max-width: 991px){
            padding: 0 5vw 0 20px;
        }
    }
    .theme-6-header-text{

        @media (min-width: 768px){
            padding-right: 13vw;
            padding-left: 0;
        }
    }
    .theme-6-feedback-left-text{

        @media (min-width: 768px){
            padding-right: 47px;
            padding-left: 78px;
        }
        @media (max-width: 767px) {
            padding-right: 15px;
            padding-left: 0;
        }
    }
    .theme-7-header-text{

        @media (min-width: 768px){
            padding-left: 4vw;
            padding-right: 0;
        }
    }
    .theme-7-prof-bg{

        @media (min-width: 768px){
            transform: rotate3d(0, 0, 1, 180deg);
        }
    }
    .theme-7-student-container{

        @media (min-width: 768px){
            padding: 0 12vw 1vw 0;
        }
    }
    .theme-7-landing-header-text{

        @media (min-width: 768px){
            padding: 18px 8.7vw 0 20px;
        }
        @media (min-width: 768px) and (max-width: 991px){
            padding: 0 5vw 0 20px;
        }
    }
    .theme-8-header-right{

        @media (min-width: 768px){
            padding: 5.15vw 0 4.2vw 9.5vw;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 5.15vw 0 4.2vw 5vw;
        }
    }
    .theme-8-header-right-img{

        @media (min-width: 768px){
            margin-left: 0;
            margin-right: -46px;
            border-radius: 0 0 0 211px;
        }
    }
    .theme-8-header-right-img img{

        @media (min-width: 768px){
            border-radius: 205px 0 0 205px;
        }
    }
    .theme-8-header-left{

        @media (min-width: 768px){
            padding: 8.6vw 11vw 4.65vw 70px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 8.6vw 5vw 4.65vw 70px;
        }
    }
    .theme-8-why-item-text{
        padding-left: 0;
        padding-right: 24px;
    }
    .theme-8-special{

        @media (min-width: 768px){
            padding: 3.1vw 11vw 3.1vw 20px;
        }
    }
    .theme-8-people-left{

        @media (min-width: 768px){
            padding-right: 11vw;
            padding-left: 20px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding-right: 3vw;
        }
    }
    .theme-8-people-right{

        @media (min-width: 768px){
            padding-left: 11vw;
            padding-right: 5.3vw;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding-left: 3vw;
            padding-right: 1.3vw;
        }
    }
    .theme-8-landing-header-text{

        @media (min-width: 768px){
            padding: 15px 10vw 20px 20px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 15px 5vw 20px 20px;
        }
    }
    .theme-9-landing-header-text, .theme-10-landing-header-text{

        @media (min-width: 768px){
            padding: 32px 7.6vw 0 5vw;
        }
        @media (min-width: 768px) and (max-width: 991px){
            padding: 30px 4vw 0 20px;
        }
    }
    .theme-10-home-about-left{

        @media (min-width: 768px){
            padding-left: 20px;
            padding-right: 0;
        }
    }
    .theme-10-home-about-right{

        @media (max-width: 767px){
            margin-left: 0;
            margin-right: auto;
        }
    }
    .theme-11-header-right{

        @media (min-width: 768px){
            padding: 5.15vw 0 6.7vw 6.5vw;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 5.15vw 0 4.2vw 5vw;
        }
    }
    .theme-11-header-right-img{

        @media (min-width: 768px){
            margin-left: 0;
            margin-right: -28px;
        }
    }
    .theme-11-header-right-img img{

        @media (min-width: 768px){
            border-radius: 13.5vw 0 0 0;
        }
    }
    .theme-11-header-left{

        @media (min-width: 768px){
            padding: 6vw 11.25vw 2.65vw 50px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 6vw 4.25vw 2.65vw 50px;
        }
    }
    .theme-11-why-item-text{
        padding-left: 0;
        padding-right: 24px;

        @media (max-width: 767px){
            padding-right: 20px;
        }
    }
    .theme-11-special{

        @media (min-width: 768px){
            padding: 3.1vw 11vw 3.1vw 20px;
        }
    }
    .theme-11-people-left{

        @media (min-width: 768px){
            padding-right: 11.4vw;
            padding-left: 20px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding-right: 3vw;
        }
    }
    .theme-11-people-right{

        @media (min-width: 768px){
            padding-left: 11vw;
            padding-right: 5.3vw;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding-left: 3vw;
            padding-right: 1.3vw;
        }
    }
    .theme-11-landing-header-text{

        @media (min-width: 768px){
            padding: 15px 10vw 20px 20px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 15px 5vw 20px 20px;
        }
    }
    .webinar-time-item{
        margin-right: 0;
        margin-left: 20px;
    }
    .paypal-course-item-info{

        @media (min-width: 551px){
            padding-left: 0;
            padding-right: 20px;
        }
    }
    .custom-checkbox-glogal input + label{
        padding: 0 2em 0 0;
    }
    .custom-checkbox-glogal input + label::before{
        left: inherit;
        right: 0;
    }
    .custom-checkbox-glogal input + label::after{
        left: inherit;
        right: 3px;
    }
    .custom-radio-glogal input[type=radio] + label{
        padding: 0 2em 0 0;
    }
    .custom-radio-glogal input[type=radio] + label::before{
        left: inherit;
        right: 0;
    }
    .custom-radio-glogal input[type=radio] + label::after{
        left: inherit;
        right: 4px;
    }
    .live-show-head{
        padding: 4.8vw 18vw 4.8vw 4.8vw;

        @media (max-width: 991px){
            padding: 4.8vw 9vw 4.8vw 4.8vw;
        }
        @media (max-width: 767px){
            padding: 15px 15px 30px;
        }
    }
    .live-share-social-block{
        left: inherit;
        right: 0;
    }
    .live-share-social-block a{

        @media (min-width: 768px){
            border-radius: 6px 0 0 6px;
        }
    }
    .product-container-wrapper .detailsBox .title{
        text-align: right;
    }
    .quiz-container .btn.p-2 {
        transform: rotate(180deg);
    }
    .answer-text{
        padding: 2px 40px 2px 60px;

        @media (max-width: 767px){
            padding: 2px 20px 2px 60px;
        }
    }
    .quiz-finish-block .correct-answer:after{
        left: 25px;

        @media (max-width: 767px){
            left: 10px;
        }
    }
    .wrong-answer:after, .correct-answer-but-not-set:after{
        left: 25px;
        right: inherit;

        @media (max-width: 767px){
            left: 10px;
        }
    }
    .answer-img {
        padding: 10px 40px 10px 60px;

        @media (max-width: 767px){
            padding: 10px 20px 10px 60px;
        }
    }
    .faq-accordion .card-body p{
        text-align: right!important;
    }
    .course-share-social-block{
        left: inherit;
        right: 0;
    }
    .course-share-social-block a{

        @media (min-width: 768px){
            border-radius: 6px 0 0 6px;
        }
    }
    .cookie-section > img{
        margin-left: 20px;
        margin-right: 0;
    }
    .cookie-section .btn{
        margin-left: 0;
        margin-right: 12px;
    }
    .cookie-section .btn-close{
        left: 15px;
        right: inherit;
    }

    [dir="ltr"]{
        display: none;
    }
    [dir="rtl"]{
        display: inherit;
    }
}
