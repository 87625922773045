
.navbar {
    position: relative;
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem 0;
    background-color: var(--nav-background);

    @media (max-width: 1199px) {
        position: fixed;
        width: 100%;
        z-index: 9;
        left: 0;
        top: 0;
        padding: 0.3rem 1rem;
        min-height: 50px;
    }
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1200px) {
        max-width: 1330px;
    }
}
.navbar-brand {
    display: inline-block;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;

    span{
        color: var(--nav-link);
        max-width: 150px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        font-size: 15px;
        vertical-align: middle;
    }
}
.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    @media (max-width: 1199px) {
        align-items: flex-start;
        height: calc(var(--app-height) - 50px);
        padding: 20px 20px 40px;
        overflow: hidden;
        overflow-y: auto;

        .nav-item{
            width: 100%;
        }
        .nav-item.nav-item-user{
            margin-left: 0;
        }
    }

    .nav-item-user{
        margin-left: 15px;

        .nav-link{
            max-width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (max-width: 1199px){
                max-width: 220px;
            }
        }
    }
    .btn{
        font-size: 15px;
        padding: 0.6em 1.55em;
    }
    .btn-1{
        margin: 0 0 0 15px;
        white-space: nowrap;

        @media (max-width: 1199px){
            margin: 20px 0 0 0;
            width: 100%;
        }
    }
}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
    color: var(--nav-link);
    text-align: center;
    display: inline-block;

    @media (max-width: 1199px) {
        text-align: left;
        font-size: 20px;

        &.dropdown-toggle{
            padding: 7px 10px;
        }
    }
    &:hover{
        color: var(--nav-link-hover);
    }
}
.nav-item.active .nav-link{
    color: var(--nav-link-hover);
}
.navbar-nav .dropdown-menu {
    position: static;
    float: none;
    margin: 0;
    background-color: #ffffff;
}
.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;

    @media (max-width: 1199px) {
        position: fixed;
        right: 0;
        width: 300px;
        background-color: var(--nav-background);
        top: 50px;
        z-index: 9;
        box-shadow: 0 4px 6px rgb(0 0 0 / 41%);
    }
}
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: 50%/100% 100% no-repeat;
}
.navbar-nav-scroll {
    max-height: 75vh;
    overflow-y: auto;
}
@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}
@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
        min-height: 60px;
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.6rem;
        padding-left: 0.6rem;
        font-size: 16px;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.header-logo{
    max-width: 160px;
    max-height: 36px;
}
#nav-icon3 {
    width: 30px;
    height: 18px;
    position: relative;
    margin: 0 auto;
    transition: .5s ease-in-out;
    cursor: pointer;
    display: inline-block
}

#nav-icon3,#nav-icon3 span {
    transform: rotate(0deg)
}

#nav-icon3 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--nav-link);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transition: .25s ease-in-out
}

#nav-icon3 span:first-child {
    top: 0
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: 8px
}

#nav-icon3 span:nth-child(4) {
    top: 16px
}

.navbar-toggler[aria-expanded=true] #nav-icon3 span:first-child {
    top: 18px;
    width: 0;
    left: 50%
}
.navbar-toggler[aria-expanded=true] #nav-icon3 span:nth-child(2) {
    transform: rotate(45deg)
}
.navbar-toggler[aria-expanded=true] #nav-icon3 span:nth-child(3) {
    transform: rotate(-45deg)
}
.navbar-toggler[aria-expanded=true] #nav-icon3 span:nth-child(4) {
    top: 18px;
    width: 0;
    left: 50%
}
.dropdown-item{
    color: black;

    @media (max-width: 1199px) {
        font-size: 16px;
        padding: 0.25rem 1.2rem;
    }

    &:hover{
        background-color: transparent;
        color: #a3a3a3;
    }
}
.dropdown-item:focus{
    background-color: transparent;
    color: #a3a3a3;
}
.dropdown-item.active{
    background-color: transparent;
    color: #a3a3a3;
}
.dropdown-toggle::after{
    transition: all .3s;
}
.nav-item.dropdown{

    &:hover{

        @media (hover: hover){
            .dropdown-menu{
                display: block;
            }

            .dropdown-toggle::after{
                transform: rotate(180deg);
            }
        }

    }
}
.links-group{
    display: flex;

    @media (max-width: 1199px) {
        width: 100%;
        justify-content: space-between;
    }
    > .nav-link:first-child{
        padding-right: 0!important;
    }
    &.show{

        + .dropdown-menu{
            display: block;
        }
        .dropdown-toggle::after{
            transform: rotate(180deg);
        }
    }
}


.svg-icons {
    transition: all 0.3s ease;
}
.dropdown-notification {
    height: 390px;
    width: 300px;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    @media (hover: hover) {
        &::-webkit-scrollbar{
            width: 8px;
        }
        &::-webkit-scrollbar-thumb{
            background: #a9a9a9;
            border-radius: 2px;
        }
        &::-webkit-scrollbar-track{
            background: #ddd;
            border-radius: 2px;
        }
    }

    @media (max-width: 1199px) {
        width: 100%;
        height: 300px;
    }
}
.notification-btn {
    padding: 3px 6px;
    color: var(--nav-link);
    opacity: .7;
    cursor: pointer;

    @media (max-width: 1199px) {
        display: inline-block;
        margin-top: 14px;
    }
    &:hover {
        text-decoration: none;

        svg {
            color: var(--nav-link-hover);
        }
    }

    i {
        position: absolute;
        right: -3px;
        font-style: normal;
        top: -5px;
        line-height: 1;
        font-size: 10px;
        font-weight: 600;
        color: var(--nav-link);
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    &:after {
        display: none;
    }
}
.notification-link {
    color: #393939;
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #dedede;
    line-height: 1.3;

    &:hover {
        text-decoration: none;
        background-color: transparent;
        text-shadow: 0 0 black;
        color: #000000;
        transition: all .2s;
    }
    .text-grey{
        color: #000000;
        opacity: .4;
    }
}
.notification-title{
    margin-bottom: 5px;

    @media (max-width: 767px) {
        font-size: 14px;
    }
}
.notification-time {
    font-size: 12px;
}
.notification-link-all {
    display: block;
    text-align: center;
    padding: 10px;
    background-color: var(--btn1-background-color);
    color: var(--btn1-text-color);
    border-radius: 0 0 2px 2px;
    transition: all .3s;

    &:hover {
        text-decoration: none;
        background-color: var(--btn1-background-hover-color);
        color: var(--btn1-text-hover-color);
    }
}
.grecaptcha-badge {
    z-index: 10;
}


footer{
    border-top: solid 1px #CECECE;
    position: relative;
    z-index: 2;
}
.footer-top{
    max-width: 511px;
    margin: 0 auto;
    padding: 54px 20px 68px;

    p{
        color: #232A35;
        margin-bottom: 33px;
    }
}
.footer-logo{
    margin-bottom: 27px;

    img{
        width: 160px;
    }
}
.footer-form{
    height: 38px;

    .form-control{
        border-radius: 3px 0 0 3px;
        border-color: #232A35;
        height: 100%;
        font-size: 15px;

        &:focus{
            box-shadow: none;
            outline: none;
            border-color: #000000;
        }
    }
    .btn{
        font-size: 14px;
        background-color: #232A35;
        color: #ffffff;
        white-space: nowrap;
        border-radius: 0 3px 3px 0;
        height: 100%;
        padding: 5px 19px;
        line-height: 1;

        &:hover{
            background-color: #000000;
        }
    }
}
.footer-bottom{
    background-color: #232A35;
}
.footer-bottom-container{
    max-width: 1100px;
    margin: 0 auto;
    padding: 48px 20px;

    @media (max-width: 575px){
        padding: 40px 15px;
    }
}
.footer-links{
    flex-wrap: wrap;
    margin-bottom: 34px;

    @media (max-width: 575px){
        flex-direction: column;
        align-items: center;
    }
    li{
        margin: 0 15px;

        @media (max-width: 767px) {
            margin: 3px 15px;
        }
        @media (max-width: 575px) {
            margin: 3px 0;
        }
        a{
            color: #fff;
            white-space: nowrap;
            padding: 5px;

            @media (max-width: 575px){
                padding: 5px 0;
            }
            &:hover{
                color: #c0c0c0;
                text-decoration: none;
            }
        }
    }
}
.footer-socials{
    margin-bottom: 70px;

    @media (max-width: 767px){
        margin-bottom: 70px;
    }
    a{
        margin: 0 5px;
    }
}
.footer-terms{
    font-size: 14px;
    margin-bottom: 10px;

    a{
        color: #ffffff;
        margin: 0 8px;
        white-space: nowrap;

        &:hover{
            color: #c0c0c0;
            text-decoration: none;
        }
    }
}
.footer-copyright{
    color: #ffffff;
    font-size: 14px;

    span{
        margin: 0 8px;

        a{
            color: #ffffff;

            &:hover{
                color: #c0c0c0;
                text-decoration: none;
            }
        }
    }
}

