

.theme-3-info-header{
    min-height: 36.5vw;
    display: flex;
    align-items: center;
    background-color: #000000;

    @media (max-width: 767px) {
        flex-direction: column;
        min-height: auto;
        padding: 35px 15px;
    }
}
.theme-3-info-header-text{
    position: relative;
    z-index: 1;
    font-size: clamp(10px, 1.172vw, 18px);
    color: #ffffff;
    margin: 0 auto;
    text-align: center;
    max-width: 700px;

    @media (max-width: 767px){
        font-size: 16px;
    }
    h1{
        font-weight: 400;
        line-height: 1.35;
        font-size: 2.5em;
        margin-bottom: .9em;

        @media (max-width: 767px){
            line-height: 1.2;
            font-size: 24px;
        }
    }
    > .btn{
        font-size: 1em;
    }
}
.theme-3-info-header-img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    opacity: .4;
}
.theme-3-info-container{
    max-width: 720px;
    margin: 0 auto;
    padding: 50px 20px 50px;
    font-size: 18px;

    @media (min-width: 1500px) {
        max-width: 1000px;
    }
    iframe{
        margin-bottom: 20px;
        margin-top: 20px;
        max-width: 100%;
        max-height: 380px;

        @media (min-width: 1500px) {
            max-height: 540px;
        }
        @media (max-width: 720px) {
            height: 51vw;
        }
    }
}

