
.live-section{
    padding: 40px 10px;

    @media (max-width: 767px){
        padding: 40px 20px;
    }
    h1{
        font-size: 25px;
        margin-bottom: 20px;
    }
    .first-live-container.slick-slider{
        padding: 0 40px;
        max-width: 1202px;
        position: relative;
        z-index: 1;

        @media (max-width: 767px){
            padding: 0;
        }
        .slick-prev, .slick-next{
            width: 30px;
            height: 30px;
        }
        .slick-next {
            right: 6px;
        }
        .slick-prev {
            left: 4px;
        }
    }
    .slick-track {
        display: flex !important;

        .slick-slide {
            height: inherit !important;

            @media (max-width: 767px){
                padding: 15px 5px;
                width: 100%!important;
            }
            .first-live-item-block {
                display: block;
                height: 100%;
            }
        }
    }
}
.first-live-container{
    max-width: 1122px;
    margin: 0 auto;
}
.first-live-item{
    width: 50%;
    padding: 15px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: 531px;
        padding: 15px 0;
        margin: 0 auto;
    }
}
.first-live-item-block{
    color: black;
    display: block;
    box-shadow: 0 3px 6px rgb(0 0 0 / 17%);
    transition: all .2s;
    position: relative;
    overflow: hidden;

    .lesson-item-label{
        position: absolute;
        top: 32px;
        right: -30px;
        background-color: #ffffff;
        transform: rotate(45deg);
        width: 150px;
        text-align: center;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    }

    &:hover{
        color: black;
        text-decoration: none;
        box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
    }
    > img{
        height: 194px;
        width: 100%;
        object-fit: cover;

        @media (max-width: 767px){
            height: 35vw;
            max-height: 194px;
        }
    }
}
.first-live-item-left{
    width: 90px;
    padding-top: 13px;
    padding-right: 11px;

    @media (max-width: 991px){
        width: 85px;
        padding-right: 10px;
    }
}
.first-live-item-info{
    padding: 17px 17px;
    height: calc(100% - 194px);
    background-color: #ffffff;

    @media (max-width: 991px) {
        padding: 15px;
    }
    @media (max-width: 767px){
        height: auto;
    }
}
.icon-live{
    border-radius: 17px;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    padding: 3px 6px 3px 7px;
    margin-bottom: 13px;
    background-color: #ffffff;
    border: solid 1px #7d7d7d;
    white-space: nowrap;

    @media (max-width: 991px){
        font-size: 12px;
    }
}
.icon-live-circle{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 3px #ffffff;
    background-color: #D41A1A;
    margin-right: 6px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 47%);
}
.icon-live-offline{
    background-color: #12AFB5;
}
.live-day-mount{

    p{
        font-size: 25px;
        margin-bottom: 2px;
    }
    h6{
        font-size: 13px;
    }
}



.first-live-item-right{
    width: calc(100% - 90px);
    border-left: solid 1px #E3E3E3;
    padding-left: 12px;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px){
        width: calc(100% - 85px);
        padding-left: 10px;
    }
    h2{
        font-size: 18px;
        line-height: 28px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;

        @media (max-width: 991px){
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.live-lessons-price{

    span{
        font-size: 19px;
        margin-right: 12px;

        @media (max-width: 991px){
            font-size: 17px;
        }
        i{
            font-style: normal;
        }
    }
    del{
        font-size: 16px;
        opacity: .62;

        @media (max-width: 991px){
            font-size: 16px;
        }
        i{
            font-style: normal;
        }
    }
}


.completed-lessons {
    margin-top: 25px;
    border-top: 2px solid silver;
    padding-top: 15px;
    max-width: 1092px;
    margin-right: 15px;
    margin-left: 15px;
}
.completed-lessons p{
    font-size: 20px;
}



.live-show-head{
    padding: 4.8vw 4.8vw 4.8vw 18vw;

    @media (max-width: 991px) {
        padding: 4.8vw 4.8vw 4.8vw 9vw;
    }
    @media (max-width: 767px){
        padding: 15px 15px 30px;
    }
}
.live-show-head-img{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    > img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    &:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, #000 0%, rgba(7, 7, 7, 0.92) 20.07%, rgba(11, 11, 11, 0.87) 29.55%, rgba(11, 11, 11, 0.87) 34.91%, rgba(12, 12, 12, 0.86) 40.07%, rgba(18, 18, 18, 0.79) 45.08%, rgba(21, 21, 21, 0.75) 48.68%, rgba(21, 21, 21, 0.75) 50.76%, rgba(22, 22, 22, 0.75) 53.67%, rgba(23, 23, 23, 0.73) 58.08%, rgba(30, 30, 30, 0.64) 63.69%, rgba(33, 33, 33, 0.6) 68.17%, rgba(41, 41, 41, 0.51) 73.01%, rgba(47, 47, 47, 0.44) 82.07%, rgba(56, 56, 56, 0.33) 88.59%, rgba(61, 61, 61, 0.27) 91.52%, rgba(67, 67, 67, 0.2) 95.11%, rgba(76, 76, 76, 0.09) 99.28%, rgba(84, 84, 84, 0) 100%);

        @media (max-width: 767px){
            background: linear-gradient(90deg, #000 0%, rgba(7, 7, 7, 0.92) 20.07%, rgba(11, 11, 11, 0.87) 29.55%, rgba(11, 11, 11, 0.87) 34.91%, rgba(12, 12, 12, 0.86) 40.07%, rgba(18, 18, 18, 0.79) 45.08%, rgba(21, 21, 21, 0.75) 60.68%, rgba(21, 21, 21, 0.75) 50.76%, rgba(22, 22, 22, 0.75) 68.67%, rgba(23, 23, 23, 0.73) 89.08%, rgba(30, 30, 30, 0.64) 99.69%, rgba(33, 33, 33, 0.6) 68.17%, rgba(41, 41, 41, 0.51) 73.01%, rgba(47, 47, 47, 0.44) 82.07%, rgba(56, 56, 56, 0.33) 88.59%, rgba(61, 61, 61, 0.27) 91.52%, rgba(67, 67, 67, 0.2) 95.11%, rgba(76, 76, 76, 0.09) 99.28%, rgba(84, 84, 84, 0) 100%);
        }
    }
}
.live-share-social-block{
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 40px;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 767px){
        flex-direction: row;
        justify-content: center;
        bottom: 0;
        right: 0;
        margin: 0 auto;
    }
    a{
        height: 34px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 0 6px 6px 0;
        margin-bottom: 6px;
        white-space: nowrap;
        color: #ffffff;

        @media (max-width: 767px){
            border-radius: 6px 6px 0 0;
            margin: 0 3px 0 3px;
        }
        &:hover{
            text-decoration: none;

            span{
                text-indent: 0;
                margin-right: 10px;
            }
        }
        span{
            text-indent: -200px;
            text-transform: uppercase;
            font-size: 14px;
            transition: all .3s;

            @media (max-width: 767px){
                display: none;
            }
        }
        img{
            height: 18px;
            width: 20px;
            flex: 0 0 20px;
        }
    }
}
.live-show-head-content{
    position: relative;
    z-index: 1;
    color: #ffffff;
    max-width: 750px;

    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
}
.start-lesson{
    font-size: 17px;
    margin-bottom: 2px;

    @media (max-width: 767px){
        margin-top: 5px;
        margin-left: 10px;
        margin-right: 5px;
    }
}
.start-lesson-time{
    font-size: 17px;

    @media (max-width: 767px){
        margin-top: 10px;
        font-size: 14px;
    }
}
.live-show-right{
    width: 150px;
    padding-top: 13px;

    @media (max-width: 767px){
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .icon-live{
        background-color: transparent;

        @media (max-width: 767px){
            margin-right: 15px;
            margin-top: 10px;
        }
    }
}
.live-show-day-mount{
    width: 100px;
    margin-bottom: 58px;
    margin-top: 12px;

    @media (max-width: 767px){
        margin-top: 0;
        margin-bottom: 15px;
    }
    p{
        font-size: 32px;
        margin-bottom: 2px;

        @media (max-width: 767px){
            font-size: 28px;
        }
        b{
            font-weight: 500;
        }
    }
    h6{
        font-size: 18px;

        @media (max-width: 767px){
            font-size: 15px;
        }

        b{
            font-weight: 500;
        }
    }
}
.font-13{
    font-size: 13px;
}
.text-grey{
    color: #BCBCBC;
}
.live-show-speaker{
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    padding-top: 3px;

    @media (max-width: 767px){
        flex-wrap: wrap;
        margin-bottom: 25px;
    }
    p{
        font-size: 27px;
        margin-bottom: 0;

        @media (max-width: 767px){
            font-size: 22px;
        }
    }
    del{
        font-size: 16px;
        opacity: 0.62;
        margin-left: 7px;
        margin-right: 5px;
    }
    &.mb-0{
        margin-bottom: 0;
    }
}

.live-show-left{
    padding-left: 15px;
    width: calc(100% - 140px);

    @media (max-width: 767px){
        padding-left: 0;
        width: 100%;
    }

    h1{
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
        display: -webkit-box;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (max-width: 767px){
            font-size: 24px;
            line-height: 1.5;
            -webkit-line-clamp: 3;
        }
    }
}
.webinar-time{
    display: flex;
    margin-bottom: 48px;

    @media (max-width: 767px){
        margin-bottom: 35px;
    }
}
.webinar-time-item{
    text-align: center;
    margin-right: 20px;

    span{
        width: 45px;
        height: 45px;
        border-radius: 7px;
        border: solid 1px #ffffff;
        padding: 5px;
        font-size: 18px;
        display: inline-flex;
        line-height: 1.2;
        align-items: center;
        justify-content: center;
        margin-bottom: 3px;
    }
    i{
        display: block;
        font-style: normal;
        font-size: 13px;
    }
}


.live-show-container{
    max-width: 935px;
    margin:  0 auto;
    padding: 40px 20px;

    h2{
        font-size: 20px;
        margin-bottom: 40px;
    }
    p{
        margin-bottom: 30px;
        line-height: 1.7;
    }
}
.live-list{
    display: flex;
    flex-wrap: wrap;

    li{
        width: 50%;
        margin-bottom: 6px;
        padding: 0 35px;
        display: flex;

        @media (max-width: 991px){
            padding: 0 4px;
        }
        @media (max-width: 767px){
            width: 100%;
        }
    }
}
.list-arrow{
    display: inline-block;
    width: 8px;
    height: 12px;
    border: solid var(--color-1);
    border-width: 0 0.2em 0.2em 0;
    transform: rotate(45deg);
    margin-right: 16px;
    margin-top: 5px;
}

.live-courses-included{
    background-color: #F4F5F8;
    padding: 53px 0 90px;

    .faq-accordion{
        max-width: 945px;

        @media (max-width: 767px){
            padding: 0 10px;
        }
        .card{
            border: none;
            box-shadow: none;
           background-color: transparent;
            border-radius: 4px !important;
            margin-bottom: 0;
        }
        .card-body{
            padding: 5px 15px 16px 138px;
            background-color: #ffffff;

            @media (max-width: 767px){
                padding: 5px 15px 15px 110px;

                p{
                    font-size: 14px;
                }
            }
        }
        h3{
            font-size: 20px;
            margin-bottom: 20px;

            @media (max-width: 767px){
                margin-bottom: 30px;
            }
        }
        .card-header{
            padding: 0;
        }
        .card-header .btn{
            background-color: transparent;
            border-radius: 4px;
            display: flex;
            align-items: center;
            border-bottom: solid 1px #ffffff;
            letter-spacing: 0;

            &[aria-expanded=true]{
                background-color: #ffffff;
            }
            &::after{
                right: 15px;

                @media (max-width: 767px){
                    right: 10px;
                }
            }
        }
        .card-header .btn > .card-title{
            width: calc(100% - 120px);
            padding-left: 15px;
            padding-right: 50px;
            font-size: 16px;
            color: black;
            line-height: 1.4;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 15px 0;

            @media (max-width: 767px) {
                width: calc(100% - 100px);
                font-size: 14px;
                line-height: 1.5;
                padding: 5px 45px 5px 10px;
            }
            span{
                line-height: inherit;
                padding-right: 0;

                @media (max-width: 767px){
                    padding-right: 0;
                }
            }
        }
    }
}
.included-live-show-day-mount{
    width: 120px;
    border-right: solid 5px #ffffff;
    padding-left: 12px;
    padding-top: 5px;
    min-height: 91px;

    @media (max-width: 767px) {
        width: 100px;
        padding-left: 8px;
    }
    p{
        font-size: 30px;
        line-height: 1.1;

        @media (max-width: 767px){
            font-size: 22px;
        }
    }
    h6{
        font-size: 16px;
        margin-bottom: 6px;

        @media (max-width: 767px){
            font-size: 14px;
        }
    }
}
.free-info{
    position: absolute;
    right: 14px;
    top: 0;
    padding: 0!important;
    font-size: 14px!important;

    @media (max-width: 767px){
        right: 7px;
    }
}

.live-page-spekars{
    max-width: 900px;
    padding: 48px 15px 100px;
    margin: 0 auto;

    h2{
        font-size: 20px;
        margin-bottom: 40px;
    }
}
.live-spekars{

    figure{
        margin-bottom: 34px;

        @media (max-width: 575px) {
            flex-direction: column;
            margin-bottom: 40px;
        }
        > img{
            width: 140px;
            height: 140px;
            border-radius: 50%;
            object-fit: cover;
        }
        figcaption{
            width: calc(100% - 140px);
            padding-left: 32px;

            @media (max-width: 575px){
                width: 100%;
                padding-left: 0;
                margin-top: 15px;
                text-align: center;
            }
            h3{
                font-size: 16px;
                margin-bottom: 11px;
            }
            p{
                line-height: 1.75;
                margin-bottom: 0;

                @media (max-width: 575px){
                    text-align: left;
                    line-height: 1.5;
                }
            }
        }
    }
}
